import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { ProfileModel } from '../models/profile/profile.model';
import { UIService } from '../services/ui.service';
import { MediaLibraryService } from '../services/mediaLibrary/media-library.service';
import { StorageUnit } from '../models/common/tabType.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnChanges {
  public openMenu: boolean;
  public appUser: ProfileModel;
  public storageConsumed: string;
  public consumedStorageTbValue: number;
  public storageLimit: number;
  public storageUnit: StorageUnit;
  storageUnitName: string;

  constructor(public router: Router, public storageService: StorageService,
              private mediaLibraryService: MediaLibraryService, private uiService: UIService) { 
              }

  ngOnInit() {
    this.openMenu = false;
    this.appUser = this.storageService.appUser;
    
    if (!this.appUser.isAdmin && this.appUser.company) {
      this.storageLimit = this.appUser.company.storageLimit;
      this.storageUnit = this.appUser.company.storageUnitId;
      this.storageUnitName = StorageUnit[this.appUser.company.storageUnitId];

      this.getCompanyConsumedStorage(this.appUser.companyId);
    }
  }

  getCompanyConsumedStorage(companyId: number) {
    this.mediaLibraryService.getCompanyStorageUsage(companyId).subscribe(x => {
      switch(this.storageUnit) { 
        case StorageUnit.GB: { 
          this.consumedStorageTbValue = x/1024/1024/1024/this.storageLimit*100;
           break; 
        } 
        case StorageUnit.TB: { 
          this.consumedStorageTbValue = x/1024/1024/1024/1024/this.storageLimit*100;
           break; 
        } 
        default: { 
            this.consumedStorageTbValue = x/1024/1024/1024/this.storageLimit*100;     
           break; 
        } 
     } 
      
      this.storageConsumed = this.formatBytes(x);
    })  
  }

  formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  openDropdown() {
    if (this.openMenu) {
    this.openMenu = false;
    } else {
      this.openMenu = true;
    }
  }

  handleLogOut() {
    this.router.navigate(['/auth/logout-redirect']);
  }

  ngOnChanges() {
    this.appUser = this.storageService.appUser;
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  onClickOutSide(event) {
    if (this.openMenu) {
      this.openMenu = false;
    }
  }

  navigateTo(link) {
    this.router.navigate([link]);
    this.openMenu = false;
    this.uiService.changeTab(100); // Because if any tab seelcted in sidemenu should be unselected
  }

}


