import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../authentication/auth.service';


@Injectable()
export class SessionGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService, private authService: AuthService) { }

  canActivate() {
    if (this.isNotLogin()) {
      return true;
    }
    this.router.navigate(['/dashboard']);
    if (this.storageService.appUser != null) {
     // this.router.navigate([`user/${this.storageService.appUser.userId}/profile`]);
    }

    return false;
  }

  private isNotLogin() {
    const isLoggedIn = this.authService.isAuthenticated() && this.storageService.appUser;

    if (isLoggedIn) {
      return false;
    }

    return true;
  }
}
