export class ContentBlockModel {
  constructor() {
    this.allowForwardSeeking = true;
    this.setFocus = false;
    this.dividerId = 0;
    this.dividerStartIndex = 0;
    this.dividerEndIndex = 0;
    this.listOptions = [];

  }
  id: any;
  title: string;
  description: string;
  contentTypeId: number;
  contentVariantId: number;
  contentHeading: string;
  contentHtml: string;
  contentHtml2: string;
  mediaUrl: string;
  mediaUrl2: string;
  displayOrder: number;
  dividerTypeId?: number;
  questionSetting: QuizSettingsModel;
  question: Array<QuestionModel>;
  contentSetting: ContentSettingsModel;
  coursePageId: any;
  showBlock: boolean;
  allowForwardSeeking: boolean;
  sortingCard: Array<SortingCardModel>;
  hotSpotItems: Array<HotSpotItemModel>;
  setFocus: boolean;
  isQuizAttempted: boolean;
  quizPercentage: number;
  learnerId: any;
  quizRetriesCount: number;
  isAttempted: boolean;
  mandatory: boolean;
  isBlockedDivider: boolean;
  dividerId: number;
  dividerStartIndex: number;
  dividerEndIndex: number;
  listOptions: Array<ContentBlockListOption>;
  style: string;
}
export enum ContentType {
  Text = 1,
  Image = 2,
  Media = 3,
  Question = 4,
  Divider = 5,
  Interactive = 6,
  Knowledge_Check = 7,
  Quote = 8,
  List = 9,
  Self_Reflection = 10
}

export enum DividerType {
  None = 1,
  Complete_Block_Directly_Above = 2,
  Complete_All_Blocks_Above = 3
}

export enum ContentVariantType {
  Text_One_Column = 1,
  Text_Two_Columns = 2,
  Text_One_Column_With_Heading = 3,
  Text_One_Column_With_Sub_Heading = 4,
  Image_Full = 5,
  Image_Centered = 6,
  Image_Two_Columns = 7,
  Image_With_Text_On_Left = 8,
  Image_Back_of_Text = 9,
  Media_Audio_One_Column = 10,
  Question_One_Column = 11,
  Media_Video_One_Column = 12,
  Divider_Button_One_Column = 13,
  Divider_Line_One_Column = 14,
  Media_Attachment_One_Column = 15,
  Interactive_Sorting_Cards = 16,
  Knowledge_Check_Single_Choice = 17,
  Knowledge_Check_Multiple_Choice = 18,
  Quote_A = 19,
  Quote_B = 20,
  Text_Note = 21,
  List_Bulleted = 22,
  List_Numbered = 23,
  List_Checkbox = 24,
  Self_Reflection_Single_Choice = 25,
  Self_Reflection_Multiple_Choice = 26,
  Interactive_Hotspots_Image = 27
}

export class QuestionModel {
  id: number;
  contentId: number;
  questionTypeId: number;
  title: string;
  correctFeedbackText: string;
  wrongFeedbackText: string;
  displayOrder: number;
  mediaType?: number;
  mediaUrl: string;
  mediaUrl2: string;
  createdBy: number;
  createdDateUtc: Date;
  updatedBy?: number;
  updatedDateUtc?: Date;
  questionOptions: Array<QuestionOptionModel>;
}

export class QuestionOptionModel {
  id: number;
  questionId: number;
  title: string;
  isAnswer: boolean;
  displayOrder: number;
  createdBy: number;
  createdDateUtc: Date;
  updatedBy?: number;
  updatedDateUtc?: Date;
  cssClass: string;
  isInEditMode: boolean;
}

export class OrderList {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  constructor(_id: number, _displayOrder: number) {
    this.id = _id;
    this.displayOrder = _displayOrder;
  }
  id: number;
  displayOrder: number;
}

export enum QuestionTypeEnum {
  Multiple_Choice = 1,
  Multiple_Response = 2
}

export enum LayoutType {
  None = 0,
  Text = 1,
  Image = 2,
  Media = 3,
  Question = 4,
  Divider = 5,
  Interactive = 6,
  Knowledge_Check = 7,
  Quote = 8,
  List = 9,
  Self_Reflection = 10
}
export class ContentSettingsModel {

  constructor() {
    this.paddingBottom = '0px';
    this.paddingTop = '0px';
    this.backgroundColor = '';
    this.dividerTextTopPadding = '88px';
  }

  id: any;
  paddingTop: string;
  paddingBottom: string;
  backgroundColor: string;
  dividerTextTopPadding: string;
}
export class QuizSettingsModel {
  constructor() {
    this.passingScore = 80;
    this.passToContinue = false;
    this.quizRetries = 10000;
    this.includeInLessonCount = true;
    this.revealAnswers = true;
  }
  id: any;
  passingScore: number;
  passToContinue: boolean;
  quizRetries: number;
  includeInLessonCount: boolean;
  revealAnswers: boolean;
}
export class PagePreviewModel {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  currentPageIndex: any;
  currentPageBlockList: Array<ContentBlockModel>;
  totalPages: any;
  hexaColor: any;
  authorName: any;
  authorImageUrl: any;
  pageName: any;
  previousNavTitle: any;
  nextNavTitle: any;
  perPagePercentage: any;
  perBlockPercentage: any;
  previewedBlocks: any;
  previewedPercentage: any;
  disabledDueToPrerequisitePassing: boolean;
  originalPageIndex: any;
  includeInLessonCount: boolean;
  isCourseStarted: boolean;
  quizRetriesCount: number;
}
export enum PreviewType {
  Desktop = 1,
  Tablet_Portrait = 2,
  Tablet_Landscape = 3,
  Mobile_Portrait = 4,
  Mobile_Landscape = 5
}
export enum VideoPlayerType {
  Small = 1,
  Medium = 2,
  FullWidth = 3
}
export class SortingCardModel {
  id: any;
  title: string;
  parentId: any;
  items: Array<SortingCardModel>;
  currentAppliedClass: string;
}
export enum DividerSettingType {
  None = 1,
  CompleteDirectlyAbove = 2,
  CompleteAll = 3
}
export class ContentBlockListOption {
  id: any;
  contentId: any;
  displayOrder: any;
  title: any;
}
export class HotSpotItemModel {
  constructor() {
    this.showDetails = false;
    this.draggedItem = false;
  }
  id: number;
  courseContentId: number;
  positionTop: number;
  positionLeft: number;
  icon: string;
  description: string;
  showDetails: boolean;
  displayOrder: number;
  draggedItem: boolean;
}

