<!--The content below is only a placeholder and can be replaced.-->
<ng-container>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.1/css/bootstrap.min.css" />
  <!-- <app-login-page></app-login-page> -->
  <router-outlet></router-outlet>
  <div class="notification {{toastModel && toastModel.icon === 'gt-icon-sucess' ? 'success' : 'error'}}" *ngIf="toastModel" [style.top]="toastModel.top">
    <span class="notification-icon">
        <span class="gt-icon {{toastModel.icon}}"></span>
    </span>

    <span class="gt-t4">{{toastModel.message}}</span>
  </div>
  <div class="main-container" [hidden]="locationUrl.location.href.indexOf('course/edit') > -1 ||
    locationUrl.location.href.indexOf('preview') > -1" *ngIf="isLogin && this.storageService.appUser && this.storageService.appUser.agreedTermsAndConditions && this.storageService.appUser.initialPasswordReset">
    <app-side-bar></app-side-bar>
    {{router.url.indexOf('course/edit') > -1 ||
    router.url.indexOf('preview') > -1}}
    <div class="right-content">
      <app-header></app-header>
    </div>
  </div>
  <!-- <app-footer></app-footer> -->
</ng-container>
