export class MediaLibraryModel {
    id: number;
    title: string;
    extension: string;
    mimeType: string;
    mediaTypeId: number;
    mediaSize: number;
    url: string;
    isUsed: boolean;
    userId: number;
    companyId: number;
}
export enum MediaLibraryItemType {
    Image = 1,
    Document = 2,
    Media = 3,
    Pexels = 4,
    Media_Audio = 5,
    Media_Video = 6
}
