import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { ToastModel } from '../models/toast.model';


@Injectable()
export class ToastService {
    private toastChanged: Subject<ToastModel>;
    public toastChanged$: Observable<ToastModel>;
    private timeoutVar: any;
    constructor() {
        this.toastChanged = new Subject();
        this.toastChanged$ = this.toastChanged.asObservable();
    }

    public toggleToast(toastModel: ToastModel) {
        this.toastChanged.next(toastModel);

        this.timeoutVar = setTimeout(() => {
            this.toastChanged.next(undefined);
        }, 5000);
    }

    public clearToast() {
      clearTimeout(this.timeoutVar);
    }
}
