import { Component } from '@angular/core';
import { UIService } from '../services/ui.service';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { ProfileService } from '../services/profile/profile.service';

@Component({
  selector: 'app-termscondition',
  templateUrl: './termscondition.component.html',
  styleUrls: ['./termscondition.component.less']
})
export class TermsconditionComponent {

  constructor(private uiService: UIService, private router: Router, private storageService: StorageService,
              private profileService: ProfileService) { }

  onAcceptClick() {
    this.profileService.markTermsAndCondition(this.storageService.appUser.id).subscribe(
      result => {
        if (result) {  }
      }
    );
    const user = this.storageService.appUser;
    user.agreedTermsAndConditions = true;
    this.storageService.appUser = user;

    this.uiService.changeSession(true);
    this.router.navigate([`/dashboard`]);
  }
  onDeclineClick() {
    this.router.navigate(['/auth/logout-redirect']);
  }

}
