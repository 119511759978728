import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'mediaType'
})
export class MediaTypePipe implements PipeTransform {
    transform(fileType: string): string {

      const extension = fileType.substring(fileType.lastIndexOf('.')).toLowerCase();
      const filePath = '../../../../../../assets/images/';
      let fileName = 'file-globle.png';
      if (extension.indexOf('pdf') > -1) {
            fileName = 'pdf.png';
        } else if (extension.indexOf('xls') > -1) {
          fileName = 'exl.png';
        } else if (extension.indexOf('ppt') > -1) {
          fileName = 'ppt.png';
        } else if (extension.indexOf('doc') > -1) {
          fileName = 'docx.png';
        } else if (extension.indexOf('mp3') > -1 || fileType.indexOf('wav') > -1) {
          fileName = 'audio-icon.png';
        } else if (extension.indexOf('txt') > -1 || fileType.indexOf('text') > -1) {
          fileName = 'txt.png';
        } else if (extension.indexOf('mp4') > -1) {
          fileName = 'video-icon.png';
        } else if (extension.indexOf('jpg') > -1 || extension.indexOf('jpeg') > -1) {
          fileName = 'jpg.png';
        } else if (extension.indexOf('png') > -1) {
          fileName = 'png.png';
        }

      return filePath + fileName;
    }
}
