<div class="login-main">
  <div class="top-nav-login">
    <img width="200" src="../../../../../assets/icons/svg/logo-2.svg">    
  </div>
  <div class="login-wraper" *ngIf="id === '' && userEmail === '' && !noEmailExist && !islinkExpired">
    <div class="login-inner-wraper">
      <h2 class="gt-t1">Reset Password</h2>
      <span class="gt-t5 inner-title full-width mb-4">Enter your e-mail</span>
      <span class="p-float-label">        
        <elm-text-field type="email" maxlength="200" [(ngModel)]="enteredEmail" size="large" autofocus="true" placeholder="E-mail" #inputEmailAddress (keyup)="validEmailAddress()" [color]="inputEmailAddress.classList.contains('ng-dirty') && enteredEmail.length === 0 ? 'danger' : 'default'" ngDefaultControl></elm-text-field>
        <!-- <label for="float-input">Email</label> -->
      </span>
      <div class=" mt-5 forget-password d-block">
        <elm-button (click)="onEmailClick()" size="large" color="primary" class="mr-5" [disabled]="enteredEmail.length === 0 || !isValidEmail">RESET</elm-button>
        <elm-button (click)="gotoLogin()" size="large" color="secondary">CANCEL</elm-button>
      </div>
    </div>

  </div>

  <div class="login-wraper" *ngIf="id !== '' && userEmail !== '' && !noEmailExist && !islinkExpired">
    <div class="login-inner-wraper">
      <h2 class="gt-t1 text-uppercase">Change Password</h2>
      <div class="notification-error" *ngIf="popError">
          <span class="notification-icon">
        <span class="gt-icon gt-icon-error"></span>
          </span>
        <span class="gt-t4">{{popErrorMessage}}</span>
      </div>
      <span class="p-float-label">
        
        <elm-text-field type="{{showICon ? 'password' : 'text'}}" maxlength="30" [(ngModel)]="password" size="large" autofocus="true" placeholder="New password" (ngModelChange)="passwordChange()"
        #inputPassword [color]="inputPassword.classList.contains('ng-dirty') && password.length === 0 ? 'danger' : 'default'" ngDefaultControl></elm-text-field>
        <div class="icons">
          <span (click)="showPassword()" *ngIf="showICon" class="gt-icon gt-icon-show-password"></span>
          <span (click)="hidePassword()" *ngIf="hideICon" class="gt-icon gt-icon-hide-password"></span>
        </div>
        <span class="notification alert-error" (mouseover)="hoverLink=true" (mouseleave)="hoverLink=false">
          <span class="gt-icon" [ngClass]="{'gt-icon-error-circle':errorCount > 0,'gt-icon-info' : errorCount === 0}"></span>
          <div class="alert-error-main" style="min-width: 300px;" *ngIf="hoverLink">
            <span class="alert-title">Password must: </span>
            <ul>
              <li [class.error-list]="password.length < 8">
                Have at least 8 characters.
              </li>
              <li [class.error-list]="!uppercasePassword">
                Have at least one capital letter.
              </li>
              <li [class.error-list]="!lowercasePassword">
                Have at least one small letter.
              </li>
              <li [class.error-list]="!numberPassword">
                Have at least one number.
              </li>
            </ul>
          </div>
        </span>
      </span>
      <span class="p-float-label">
        <elm-text-field type="{{showIConConform ? 'password' : 'text'}}" maxlength="30" [(ngModel)]="reEnteredPassword" size="large" autofocus="true" placeholder="Confirm password"
        #inputConfirmPassword [color]="inputConfirmPassword.classList.contains('ng-dirty') && reEnteredPassword.length === 0 ? 'danger' : 'default'" ngDefaultControl></elm-text-field>
        <div class="icons">
          <span (click)="showPasswordConform()" *ngIf="showIConConform" class="gt-icon gt-icon-show-password"></span>
          <span (click)="hidePasswordConform()" *ngIf="hideIConConform" class="gt-icon gt-icon-hide-password"></span>
        </div>
      </span>
      <div class=" mt-5 forget-password d-block">
        <elm-button (click)="onPasswordReset()" size="large" color="primary" class="mr-5" [disabled]="password.length === 0 || reEnteredPassword.length === 0">CHANGE</elm-button>
        <elm-button (click)="gotoLogin()" size="large" color="secondary">CANCEL</elm-button>
      </div>

    </div>
  </div>

  <div class="login-wraper forget-password-error" *ngIf="noEmailExist">
    <div class="login-inner-wraper">
      <span class="gt-t2">
        Oops, there doesn’t seem to be a eloomi account with that e-mail.
        Try with another e-mail or contact our support team here<br>
        <a href="javascript:;">support@eloomi.com</a>
      </span>
      <div class=" mt-5 forget-password d-block">
        <elm-button  size="large" color="primary" class="mr-5">CONTACT</elm-button>
        <elm-button (click)="resetForm()" size="large" color="secondary">Cancel</elm-button>
      </div>
    </div>
  </div>
  <div class="login-wraper forget-password-error" *ngIf="islinkExpired">
    <div class="login-inner-wraper">
      <span class="gt-t2">
        This link is either expired or used already<br>
        Please request "Reset Password" again.
      </span>
      <div class=" mt-5 forget-password d-block">
        <elm-button (click)="resetForm()" size="large" color="primary" class="mr-5">RESET</elm-button>
        <elm-button (click)="gotoLogin()" size="large" color="secondary">CANCEL</elm-button>
      </div>
    </div>
  </div>


</div>
<!-- <p-progressSpinner *ngIf="isAsyncCall"></p-progressSpinner> -->
<p-progressSpinner *ngIf="isAsyncCall"></p-progressSpinner>
