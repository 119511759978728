import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MediaLibraryUrls } from '../../constants/mediaLibrary/mediaLibraryUrls.const';
import { MediaLibraryItemType, MediaLibraryModel } from '../../models/media/mediaLibrary.model';
import { environment } from 'src/environments/environment';

const httpOptions = {  
  headers : new HttpHeaders({  
    'Authorization':`${environment.pexelsKey}`  
  })
}  

@Injectable()
export class MediaLibraryService {

  headers: HttpHeaders;

  constructor(private httpClient: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers.append('contentType', 'application/json');
  }
  // Todo: Replace with company end point. Remove this from front-end and back-end
  getMediaLibraryItems(mediaTypeId: any, searchQuery: string, pageIndex: any, pageSize: any): Observable<Array<any>> {
    return this.httpClient
      .get(MediaLibraryUrls.GET_MEDIA_FILES(mediaTypeId, searchQuery, pageIndex, pageSize), { headers: this.headers })
      .pipe(map(res => <Array<any>> (res)));
  }

  // tslint:disable-next-line:max-line-length
  getMediaLibraryItemsByCompany(mediaTypeId: number, companyId: number, searchQuery: string, pageIndex: any, pageSize: any): Observable<Array<any>> {

    const url = MediaLibraryUrls.GET_MEDIA_FILES_BY_COMPANY(mediaTypeId, companyId, searchQuery, pageIndex, pageSize);

    if (mediaTypeId === MediaLibraryItemType.Pexels.valueOf()) {
      return this.httpClient
      .get<any>(url, httpOptions)
      .pipe(catchError(this.handelError));
    } else {
      return this.httpClient
      .get(url, { headers: this.headers })
      .pipe(map(res => <Array<any>> (res)));
    }
  }
  
  getCompanyStorageUsage(companyId: number): Observable<number> {

    const url = MediaLibraryUrls.GET_COMPANY_STORAGE_USAGE(companyId);

    return this.httpClient
    .get(url, { headers: this.headers })
    .pipe(map(res => <number> (res)));
  
  }

  handelError(error: any){  
    return throwError(error.message || "Server Error");  
  } 

  deleteMediaLibraryItem(mediaFileId: any) {
    return this.httpClient
    .delete(MediaLibraryUrls.DELETE_MEDIA_FILE(mediaFileId), { headers: this.headers })
    .pipe(map(res => <Array<any>> (res)));
  }

  deleteMediaItem(companyId: number, mediaFileId: number) {
    return this.httpClient
    .delete(MediaLibraryUrls.DELETE_MEDIA_FILE_BY_ID(companyId, mediaFileId), { headers: this.headers })
    .pipe(map(res => <Array<any>> (res)));
  }

}
