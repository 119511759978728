import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { UIService } from '../services/ui.service';
import { TabType } from '../models/common/tabType.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.less']
})
export class SideBarComponent implements OnInit {

  selectedIndex: any;
  isAdmin: boolean;

  constructor(public storageService: StorageService, private uiService: UIService, public router: Router) { }

  ngOnInit() {

    this.isAdmin = this.storageService.appUser.isAdmin;
    const url = window.location.href;
    const lastPart = url.substr(url.lastIndexOf('/') + 1).toLocaleLowerCase()     
    if (lastPart === "dashboard") {
      this.selectedIndex = TabType.DashBoard.valueOf();
    } else if (lastPart === "courses") {
      this.selectedIndex = TabType.Courses.valueOf();
    } 

    this.uiService.tabChanged$
      .subscribe(selectedIndex => {
        this.selectedIndex = selectedIndex
      });
  }

  redirectToStats() {
    if (this.isAdmin) {

    } else {
      this.router.navigate(['/client-stats']);
    }
}

  get tabType() { return TabType; }

}
