import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { catchError } from "rxjs/operators";
import { StorageService } from "../services/storage.service";
import { UIService } from "../services/ui.service";

@Injectable()
export class HttpClientErrorInterceptor implements HttpInterceptor {
  constructor(
    private uiService: UIService,
    private storageService: StorageService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          const appUser = this.storageService.appUser;
          this.storageService.clearStorage();
          this.uiService.changeSession(false);
          this.authService.logout();
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
