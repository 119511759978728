<div class="login-main">
  <div class="top-nav-login">
    <img width="200" src="../../../../../assets/icons/svg/logo-2.svg">
    <!-- <nav>
      <ul>
        <li><a href="javascript:;">HOME</a></li>
        <li><a href="javascript:;">ABOUT</a></li>
        <li><a href="javascript:;">CONTACT</a></li>
      </ul>
    </nav> -->
  </div>
  <div class="login-wraper">
    <div class="login-inner-wraper">
      <h2 class="gt-t1 text-uppercase">Reset Password</h2>
      <div class="notification-error" *ngIf="invalidPassword">
          <span class="notification-icon">
          <span class="gt-icon gt-icon-error"></span>
          </span>
          <span class="gt-t4">{{validationMessage}}</span>
       </div>
       <span class="gt-t5 inner-title text-center full-width mb-4">Enter a new password</span>
       <span class="p-float-label">
        <input id="float-input" type="{{showOldPassword ? 'text' : 'password'}}" [(ngModel)]="oldPassword" pInputText>
        <div class="icons change-password">
          <span (click)="showOldPassword = true" *ngIf="showOldPassword === false" class="gt-icon gt-icon-show-password"></span>
          <span (click)="showOldPassword = false;" *ngIf="showOldPassword === true" class="gt-icon gt-icon-hide-password"></span>
        </div>
        <label for="float-input">Old Password</label>
        <span class="notification alert-error" *ngIf="isChangePasswordClicked && oldPassword.length === 0">
          <span class="gt-icon gt-icon-error-circle" (mouseover)="hoverOldPassword = true" (mouseleave)="hoverOldPassword = false"></span>
          <div class="alert-error-main" [hidden]="!hoverOldPassword">
            <span class="text">Please enter old password.</span>
          </div>
        </span>
      </span>
      <span class="p-float-label">
        <input id="float-input" maxlength="30" [(ngModel)]="newPassword" (ngModelChange)="passwordTextChange()" type="{{showPassword ? 'text' : 'password'}}" pInputText>
        <label for="float-input">New Password</label>
        <div class="icons change-password">
          <span (click)="showPassword = true" *ngIf="showPassword === false" class="gt-icon gt-icon-show-password"></span>
          <span (click)="showPassword = false" *ngIf="showPassword === true" class="gt-icon gt-icon-hide-password"></span>
        </div>
        <span class="notification alert-error" (mouseover)="showPasswordStrength = true" (mouseleave)="showPasswordStrength = false">
          <span class="gt-icon" [ngClass]="{'gt-icon-error-circle':errorCount > 0,'gt-icon-info' : errorCount === 0}"></span>
          <div class="alert-error-main" style="min-width: 300px;" *ngIf="showPasswordStrength">
            <span class="alert-title">Password must: </span>
            <ul>
              <li [class.error-list]="errorCount > 0 && newPassword.length < 8">
                Have at least 8 characters.
              </li>
              <li [class.error-list]="errorCount > 0 && !uppercasePassword">
                Have at least one capital letter.
              </li>
              <li [class.error-list]="errorCount > 0 && !lowercasePassword">
                Have at least one small letter.
              </li>
              <li [class.error-list]="errorCount > 0 && !numberPassword">
                Have at least one number.
              </li>
            </ul>
          </div>
        </span>
      </span>

      <div class="mt-5">
                    <!-- <button class="gt-rounded gt-rounded-grey ml-5" >CANCEL</button> -->
          <elm-button (click)="changePassword()" size="large" color="primary" class="mr-5" [disabled]="oldPassword?.length === 0 || newPassword?.length === 0">Change</elm-button>
          <elm-button (click)="onCancel()" size="large" color="secondary">Cancel</elm-button>
      </div>
    </div>

  </div>
</div>
<p-progressSpinner *ngIf="isAsyncCall"></p-progressSpinner>



