import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { LoginModel } from 'src/app/core/models/auth/Login.model';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.less']
})
export class LoginPageComponent {
@ViewChild('loginWrapper', {static: true}) loginWrap: ElementRef;
  model: LoginModel;
  invalidEmailorPassword = false;
  validationMessage = '';
  isAsyncCall = false;
  isLoggedIn = false;
  isMobile: boolean;

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.model.email.length > 0 && this.model.password.length > 0) {
      this.onSubmitClick();
    }
  }

  constructor(private authService: AuthService, private router: Router, private deviceService: DeviceDetectorService) {
    this.model = new LoginModel();
    this.checkMobileScreen();
  }

  checkMobileScreen() {
    this.isMobile = this.deviceService.isMobile();
  }

  onSubmitClick() {
    if (!this.isEmail(this.model.email)) {
      this.showInvalidEmailorPassword('Email address is not valid');
      return;
    }
    this.isAsyncCall = true;
    this.authService
      .login(this.model)
      .subscribe(res => {

        if (res.code ===  'access_denied') {
          this.showInvalidEmailorPassword('Invalid Email address or Password');
          this.isAsyncCall = false;
        } else {
          this.isLoggedIn = true;
          this.authService.processLogin(res[1], false);
        }
      },
      () => {
        this.isAsyncCall = false;
      });
  }
  onForgotPasswordClick() {
    this.router.navigate(['auth/forgot-password']);
  }
  showInvalidEmailorPassword(message) {
    this.invalidEmailorPassword = true;
    this.validationMessage = message;
    setTimeout(() => {
      this.invalidEmailorPassword = false;
    }, 7000);
    this.model.email = '';
    this.model.password = '';
  }
  isEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
}
