import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastModel } from 'src/app/core/models/toast.model';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {
  showICon: boolean;
  hideICon: boolean;
  showIConConform: boolean;
  hideIConConform: boolean;

  id = '';
  userEmail = '';
  enteredEmail = '';

  noEmailExist = false;

  password = '';
  reEnteredPassword = '';

  hoverLink = false;
  lowercasePassword = false;
  uppercasePassword = false;
  numberPassword = false;
  passwordStat: boolean;
  errorCount = 0;

  popError = false;
  popErrorMessage = '';

  isAsyncCall = false;
  isValidEmail: boolean;
  islinkExpired = false;

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.id === '' && this.userEmail === '' && !this.noEmailExist && this.enteredEmail.length > 0 && this.isValidEmail) {
      this.onEmailClick();
    } else if (this.id !== '' && this.userEmail !== '' && !this.noEmailExist &&
               this.password.length > 0 && this.reEnteredPassword.length > 0) {
      this.onPasswordReset();
    }
  }

  constructor(private activatedRoute: ActivatedRoute, private profileService: ProfileService, private router: Router,
              private toastService: ToastService, private authService: AuthService) { }

  ngOnInit() {
    this.showICon = true;
    this.hideICon = false;
    this.showIConConform = true;
    this.hideIConConform = false;

    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard']);
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.id = '';
      this.userEmail = '';

      if (params.id) {
        this.id = params.id;
        this.checkURLValidity(this.id);
      }
      if (params.email) {
        this.userEmail = params.email;
      }
    });
  }

  validateEmail(email: string) {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validEmailAddress() {
    this.isValidEmail = this.validateEmail(this.enteredEmail);
  }

  showPassword() {
    this.showICon = false;
    this.hideICon = true;
  }
  hidePassword() {
    this.showICon = true;
    this.hideICon = false;
  }
  showPasswordConform() {
    this.showIConConform = false;
    this.hideIConConform = true;
  }
  hidePasswordConform() {
    this.showIConConform = true;
    this.hideIConConform = false;
  }
  getUserInfoByEmail(email: any) {
    this.isAsyncCall = true;
    this.profileService.getProfileByEmail(email).subscribe(
      result => {
        if (result && result.id) {
          this.noEmailExist = false;
          this.profileService.sendpasswordResestEmail({ emailAddress: email }).subscribe(
            result2 => {
              // show alert
              this.isAsyncCall = false;
              this.showToastMsg(true, 'Password reset link has been sent to the provided email address');
              this.gotoLogin();
            },
            () => {
              this.isAsyncCall = false;
            }
          );
          // this.userType = 1;
          // this.externalEmail = result.externalId.replace('auth0|', '');
        } else {
          this.noEmailExist = true;
          this.isAsyncCall = false;
        }
      }

    );
  }
  gotoLogin() {
    this.router.navigate(['auth/login']);
  }

  onEmailClick() {
    this.getUserInfoByEmail(this.enteredEmail);
  }
  onPasswordReset() {
    this.hoverLink = false;
    if (this.errorCount > 0) {
      this.hoverLink = true;
      return;
    } else if (this.password !== this.reEnteredPassword) {
      this.showToastMsg(false, 'Passwords does not matches');
      return;
    }
    this.isAsyncCall = true;
    const objResetPassword = {
      requestId: this.id,
      emailAddress: this.userEmail,
      password: this.password
    };
    this.profileService.resetPassword(objResetPassword).subscribe(
      result => {
        this.isAsyncCall = false;
        if (result) {
          this.showToastMsg(true, 'Password changed successfully');
          this.gotoLogin();
        } else {

        }
      },
      () => {
        this.isAsyncCall = false;
      }
    );
  }
  showToastMsg(val, msg) {
    const model: ToastModel = { icon: val ? 'gt-icon-sucess' : 'gt-icon-error', message: msg };
    this.toastService.toggleToast(model);
  }
  checkURLValidity(id) {
    this.profileService.getResetPasswordRequest(id).subscribe(
      result => {
        if (result.isConsumed || this.isDateExpired(result.requestUtcDate)) {
          this.islinkExpired = true;
        }
      }
    );
  }
  isDateExpired(createDate: Date): boolean {
    const now = +new Date();

    // This is returned as: July 18, 2018 at 3:48:00 AM UTC+1
    const createdAt = +new Date(createDate);

    const oneDay = 60 * 60 * 24 * 1000;
    return (now - createdAt) > oneDay;
  }
  passwordChange() {

    this.initializeChecks();
    this.passwordStat = false;
    const numberM = /([0-9])/;
    const lowercasePassword = /([a-z])/;
    const uppercasePassword = /([A-Z])/;

    const password = this.password;

    if (!password) {
      return;
    }

    if (password.match(lowercasePassword)) {
      this.lowercasePassword = true;
    } else {
      this.lowercasePassword = false;
      this.errorCount += 1;
    }
    if (password.match(uppercasePassword)) {
      this.uppercasePassword = true;
    } else {
      this.uppercasePassword = false;
      this.errorCount += 1;
    }
    if (password.match(numberM)) {
      this.numberPassword = true;
    } else {
      this.numberPassword = false;
      this.errorCount += 1;
    }
    if (password.length < 8) {
      this.errorCount += 1;
    }
  }
  initializeChecks() {
    this.errorCount = 0;
    this.numberPassword = false;
    this.uppercasePassword = false;
    this.lowercasePassword = false;
  }

  resetForm() {
    this.id = '';
    this.userEmail = '';
    this.noEmailExist = false;
    this.islinkExpired = false;
  }
}
