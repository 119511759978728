import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { TabType } from '../models/common/tabType.enum';
import { MediaLibraryModel } from '../models/media/mediaLibrary.model';

@Injectable()
export class UIService {
  sessionChanged$: Observable<boolean>;
  private sessionChangeSubject: Subject<boolean>;

  tabChanged$: Observable<TabType>;
  private tabChangeSubject: Subject<TabType>;

  previewScreenChange$: Observable<string>;
  private previewScreenChangeSubject: BehaviorSubject<string>;

  knowledgeCheckContentChange$: Observable<any>;
  private knowledgeCheckContentChangeSubject: BehaviorSubject<any>;

  onCourseLoad$: Observable<boolean>;
  private courseLoadSubject: Subject<boolean>;

  onSortingDragStart$: Observable<boolean>;
  private sortingDragStartSubject: Subject<boolean>;

  onMediaLibraryItemSelection$: Observable<MediaLibraryModel>;
  private mediaLibraryItemSelectionSubject: Subject<MediaLibraryModel>;

  onMediaLibraryModalScroll$: Observable<void>;
  private mediaLibraryModalScrollSubject: Subject<void>;


  constructor() {
    this.sessionChangeSubject = new Subject<boolean>();
    this.sessionChanged$ = this.sessionChangeSubject.asObservable();

    this.tabChangeSubject = new Subject<number>();
    this.tabChanged$ = this.tabChangeSubject.asObservable();

    this.previewScreenChangeSubject = new BehaviorSubject<string>('Desktop');
    this.previewScreenChange$ = this.previewScreenChangeSubject.asObservable();

    this.knowledgeCheckContentChangeSubject = new BehaviorSubject<any>(null);
    this.knowledgeCheckContentChange$ = this.knowledgeCheckContentChangeSubject.asObservable();

    this.courseLoadSubject = new Subject<boolean>();
    this.onCourseLoad$ = this.courseLoadSubject.asObservable();

    this.sortingDragStartSubject = new Subject<boolean>();
    this.onSortingDragStart$ = this.sortingDragStartSubject.asObservable();

    this.mediaLibraryItemSelectionSubject = new Subject<MediaLibraryModel>();
    this.onMediaLibraryItemSelection$ = this.mediaLibraryItemSelectionSubject.asObservable();

    this.mediaLibraryModalScrollSubject = new Subject<void>();
    this.onMediaLibraryModalScroll$ = this.mediaLibraryModalScrollSubject.asObservable();
  }

  changeSession(status: boolean) {
    this.sessionChangeSubject.next(status);
  }

  changeTab(tabOption: TabType) {
    this.tabChangeSubject.next(tabOption);
  }

  onPreviewScreenChange(previewType: string) {
    this.previewScreenChangeSubject.next(previewType);
  }

  isCourseLoading(status: boolean) {
    this.courseLoadSubject.next(status);
  }

  onKnowledgeCheckContentChange(question: any) {
    this.knowledgeCheckContentChangeSubject.next(question);
  }

  sortingDragStart(status: boolean) {
    this.sortingDragStartSubject.next(status);
  }

  mediaLibraryItemSelection(mediaLibraryModel: MediaLibraryModel) {
    this.mediaLibraryItemSelectionSubject.next(mediaLibraryModel);
  }

  mediaLibraryModalScroll() {
    this.mediaLibraryModalScrollSubject.next();
  }
}
