import { Component } from '@angular/core';
import { UIService } from 'src/app/core/services/ui.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-logout-redirect',
  templateUrl: './logout-redirect.component.html',
  styleUrls: ['./logout-redirect.component.less']
})
export class LogoutRedirectComponent {

  constructor(uiService: UIService, storageService: StorageService, router: Router, authService: AuthService) {

    storageService.clearStorage();
    uiService.changeSession(false);

    authService.logout();
  }
}
