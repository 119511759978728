import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../authentication/auth.service';
import { StorageService } from '../services/storage.service';
import { UIService } from '../services/ui.service';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private storageService: StorageService,
              private uiService: UIService) {
  }

  canActivate() {

    if (this.isLogin()) {
      return true;
    }

    this.router.navigate(['/auth/login']);

    return false;
  }

  private isLogin() {
    if (this.authService.isAuthenticated() && this.storageService.appUser != null) {
      return true;
    } else {
      this.uiService.changeSession(false);
      return false;
    }
  }
}
