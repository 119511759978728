import { environment } from '../../../../environments/environment';

export class ProfileUrls {

  static CREATE_PROFILE(): any {
    return `${environment.liveUrl}/Users`;
  }
  static UPDATE_PROFILE(userId: any): any {
    return `${environment.liveUrl}/Users/${userId}`;
  }
  static GET_PROFILE_LIST(isAdmin: boolean, searchQuery: string, pageIndex: any, pageSize: any): any {
    if (searchQuery.length === 0) {
      return `${environment.liveUrl}/Users?isAdmin=${isAdmin}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    } else {
      return `${environment.liveUrl}/Users?isAdmin=${isAdmin}&searchquery=${searchQuery}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }
  }
  static GET_PROFILE_BY_ID(id: any): any {
    return `${environment.liveUrl}/Users/${id}`;
  }
  static GET_PROFILE_BY_EMAIL(email: any): any {
    return `${environment.liveUrl}/Users/Email/${email}`;
  }
  static GET_USER_HASH(message: string): string {
    return `${environment.liveUrl}/Users/hash/${message}`;
  }
  static PASSWORD_RESET_EMAIL(): any {
    return `${environment.liveUrl}/Users/requestresetpassword`;
  }
  static GET_PASSWORD_RESET_REQUEST(uniqueId: any): any {
    return `${environment.liveUrl}/Users/getresetpasswordrequest?uniqueId=${uniqueId}`;
  }
  static RESET_PASSWORD(): any {
    return `${environment.liveUrl}/Users/resetpassword`;
  }
  static MARK_TERMS_CONDITION(userId: any) {
    return `${environment.liveUrl}/Users/marktac?userId=${userId}`;
  }
  static CHANGE_PASSWORD(userId: any) {
    return `${environment.liveUrl}/Users/${userId}/changepassword`;
  }
  static UPLOAD_PROFILE_IMAGE(userId: any): any {
    return `${environment.liveUrl}/Users/${userId}/profileimage`;
  }
  static UPDATE_PROFILE_STATUS(userId: any): any {
    return `${environment.liveUrl}/Users/${userId}/block`;
  }
  static DELETE_PROFILE(userId: number, externalId: string): any {
    return `${environment.liveUrl}/Users/${userId}/${externalId}`;
  }
  static GET_PROFILE_COUNT(): string {
    return `${environment.liveUrl}/users/count`;
  }
}
