export enum TabType {
    Home = 1,
    DashBoard = 2,
    Courses = 3,
    MyCourses = 4,
    Stats = 5,
    MyFiles = 6,
    Support = 7
  }
export enum ClientDashboardTabType {
  MyCourses = 1,
  AllCourses = 2,
  RecommendedCourses = 3
}
export enum AdminDashboardTabType {
  AllClients = 1,
  AllUsers = 2,
  AllCourses = 3,
  AllStats = 4
}
export enum QuizMediaType {
  Image = 1,
  Video = 2
}

export enum MediaType {
  All = 0,
  Image = 1,
  Document = 2,
  Media = 3,
  Pexels = 4,
  Media_Audio = 5,
  Media_Video = 6
}

export enum StorageUnit {
  GB = 1,
  TB = 2
}
