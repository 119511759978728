<div class="term-outer">
    <div class="term-container">
        <div class="term-dialog">

            <div class="term-header text-center">
                <h2>Terms and Conditions</h2>
            </div>
            <div class="term-main">
                <!-- <p><strong>General Site Usage</strong></p>
                <p><strong>Last Revised: June 16, 2020</strong></p> -->
                <p>These Terms and Conditions apply to all use of the eloomi library and content editor tool from eloomi
                    and the various content providers.
                    <br>
                    By accepting the Terms and Conditions or starting to use the content or the editor tool, you agree,
                    on behalf of the entity you represent, to abide by the Terms.
                </p>
                <ol>
                    <li>
                        <label>eloomi - CONTENT LIBRARY AND EDITOR TOOL</label> <br>
                        <p>eloomi provides access to the eloomi e-learning library and editor tool also referred to as
                            eloomi. eloomi contains multiple e-learning modules and programs from various content
                            providers
                            within different learning areas. eloomi provides the possibility to pick and choose
                            different
                            modules and programs, certain flexibility to edit the modules with Customer branding and
                            adding
                            or removing e-learning elements and publishing the e-learning content in Customer's own LMS.
                        </p>
                    </li>
                    <li>
                        <label>SUBSCRIPTION</label><br>
                        <p>

                            A subscription is based on a 12-month period.<br>
                            Within the scope users and the selected learning areas, and subject to payment of the agreed
                            charges, eloomi grants the Customer a non-exclusive, time-limited, revocable,
                            non-transferrable
                            right to use eloomi as intended and contemplated, including any information provided by
                            eloomi,
                            on the terms and conditions reflected in the agreement.
                            <br>
                            The Customer's right of use covers the selected online modules and programs for internal use
                            within Customer's organization for the number of unique users selected in the price range
                            and
                            only as long as the subscription is active.<br>
                            The number of unique users applies per 12-month subscription period. If the number of unique
                            users is exceeded, the additional number of users will be settled by the end of the 12-month
                            subscription period. Settlement is based on the average price per user from the selected
                            number
                            of unique users in the price range (and payment for such additional user is for a full
                            12-month
                            subscription period notwithstanding that such additional user is added during a 12-month
                            subscription period and therefore might not enjoy the full right of use during the full
                            subscription term).
                            <br>
                            The Customer will provide eloomi with all Information and documentation requested by eloomi in order for eloomi to verify that the Customer is in compliance with this Agreement.
                        </p>
                        <ol class="sub-items">
                            <li>
                                <label>Free Trial</label><br>
                                <p>If you register for a free trial, we will make the applicable Subscription Service
                                    available to you on a trial basis free of charge until the earlier of (a) the end of
                                    the free trial period (if not terminated earlier) or (b) the start date of your paid
                                    subscription. Unless you purchase a subscription to the applicable Subscription
                                    Service before the end of the free trial, all of your data in the Subscription
                                    Service may be permanently deleted at the end of the trial, and we will not recover
                                    it. If we include additional terms and conditions on the trial registration web
                                    page, those will apply as well.</p>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <label>RESTRICTIONS </label><br>
                        <p class="margin-bottom-0">The Customer and its authorised users shall not:</p>
                        <ol>
                            <li>
                                use eloomi in violation of any applicable law;
                            </li>
                            <li>
                                copy, use, distribute, republish, download, display, transmit, sell, rent, lease,
                                host,
                                or sub-license eloomi, save as explicitly permitted under Clause 2;
                            </li>
                            <li>
                                use eloomi to store or transmit infringing, libellous or otherwise unlawful or
                                tortious
                                material, or store or transmit material in violation of third-party privacy rights;
                            </li>
                            <li>
                                attempt to interact with the operating system(s) underlying eloomi, or modify,
                                create
                                derivative works of, adapt, translate, reverse engineer, decompile, or otherwise
                                attempt
                                to discover the source code in eloomi (save as permitted by law); or
                            </li>
                            <li>
                                remove any copyright notices, trademarks, trademark credits, confidentiality notice,
                                mark, legend or other information included in eloomi.
                            </li>

                        </ol>
                        <p>
                            The customer is aware that a satisfactory use of eloomi may from time to time require
                            certain
                            minimum and compatibility requirements in respect of hardware, IT systems or third-party
                            licenses. It is the sole responsibility of the Customer to meet such requirements, which
                            may
                            be
                            updated from time to time.
                        </p>
                    </li>
                    <li>
                        <label>TERMINATION OF SUBSCRIPTION</label> <br>
                        <p>
                            Both parties may terminate the agreement in whole or In part with one month's notice before
                            -
                            and to take effect at - the end of a subscription period.
                            Termination must be in writing. If the subscription is not terminated with one month's
                            written
                            notice before the end of a subscription period, the agreement Is automatically renewed for a
                            new
                            corresponding subscription period and the Customer accepts that eloomi continues to charge
                            the
                            subscription (based on the number of users at the end of the last subscription period) as
                            adjusted in accordance with Clause 5 regardless of whether the services are used or not.
                            <br>
                            Upon expiry of the agreement, the Customer shall cease all use of eloomi and, at the
                            discretion
                            of eloomi, delete and/or return any material belonging to eloomi.
                        </p>
                    </li>
                    <li>
                        <label> PAYMENT TERMS </label> <br>
                        <ul style="list-style-type: disc;">
                            <li>
                                Subscriptions are invoiced up front before the beginning of a 12-month subscription
                                period
                            </li>
                            <li>
                                All prices mentioned in this contract are in DKK and excluding VAT, unless otherwise
                                stated
                            </li>
                            <li>
                                Payment terms: Date of invoice + 14 days
                            </li>
                            <li>
                                Due date is calculated as the date of invoice + 14 days
                            </li>
                            <li>
                                If payment occurs after the due date, 2 % interest rate is added to the invoice
                                amount
                                at the start of every month
                            </li>

                        </ul>
                        <p>
                            eloomi Is entitled to increase the charges once a year on 1 January - however, to be
                            applied
                            to the Customer at the first renewal thereafter - with the higher of (i) the development
                            in
                            the producer price index for information services (in Danish "producentprisindekset for
                            informationstjenester") published by Statistics Denmark by comparing the index for
                            October
                            in the year prior to the yearly adjustment with October the year before or (ii) 3%
                        </p>
                    </li>
                    <li>
                        <label>FAIR USE POLICY</label>
                        <br>
                        <p>
                            Uploading own content to the eloomi platform is unlimited within the agreed subscription,
                            unless
                            the Customer uses the services in an unusual way outside the purpose of the services. In
                            such
                            case eloomi retains the right to limit or suspend service to the Customer and the Customer
                            may be
                            contacted by eloomi with a tailored commercial proposal. eloomi acknowledges that an unusual
                            way
                            outside the purpose of the services might not be intentional and eloomi is obligated to
                            invoke
                            the fair use policy to the Customer if an instance occurs, with one week's notice, to find a
                            mutually agreeable solution, and eloomi must also document the point of over-usage. In the
                            case
                            that there will be no agreeable solution or plan arranged, eloomi may limit or suspend
                            services
                            until the situation is resolved.
                        </p>
                    </li>
                    <li>
                        <label>INTELLECTUAL PROPERTY RIGHTS</label> <br>
                        <p>
                            All intellectual property rights pertaining to eloomi as well as any modifications and
                            enhancements therein and any other deliverables by eloomi under the agreement, shall accrue
                            to or
                            continue to vest in eloomi and/or Its third-party licensors, including the right to amend,
                            modify, redistribute and transfer such intellectual property rights.
                            All data or material originating from the Customer belongs to the Customer. It is the sole
                            responsibility of the Customer, at its own costs, to download or extract such data from
                            eloomi
                            (or request eloomi to do so) prior to the termination of the agreement.
                            eloomi is entitled to use any ideas, suggestions, etc. provided by the Customer to eloomi,
                            e.g.
                            concerning updates and development of new functions/products. The Customer hereby
                            irrevocable
                            assigns and transfers any and all Intellectual Property Rights to such ideas, suggestions,
                            etc.
                            to eloomi free of charge.
                        </p>
                    </li>
                    <li>
                        <label>WARRANTIES</label>
                        <br>
                        <p>
                            eloomi and the modules are general and non-customized learning and/or support tools provided
                            to
                            the Customer "as is". <br>
                            It Is the sole responsibility of the Customer to ascertain that eloomi and the modules meet
                            the
                            requirements of the Customer. eloomi and the modules are not a substitute for legal,
                            technical
                            or other professional advice. <br>
                            Under no circumstances do eloomi and Its third-party licensors accept any responsibility or
                            liability for the use of eloomi and the modules, including to achieve compliance within a
                            specific area or to achieve specific results or outcomes.<br>
                            eloomi and Its third-party licensors provide no - and disclaim any and all - express and
                            implied
                            warranties in relation to eloomi and the modules, including those of merchantability or
                            fitness
                            for a particular purpose or achievement of specific results.<br>
                            In particular, eloomi and its third party-licensors exclude and disclaim any warranty that
                            the
                            use of eloomi and the modules will be uninterrupted or free of defects or that defects will
                            be
                            rectified. <br>
                            eloomi and its third-party licensor undertake no obligation to provide the Customer with
                            access
                            to any additional information or to update eloomi and the modules at any time or to correct
                            any
                            inaccuracies therein. <br>
                            In case of any technical problems with eloomi, eloomi will, however, try its best to assist
                            the
                            Customer. <br>
                        </p>

                    </li>
                    <li>
                        <label>LIABILITY</label> <br>
                        <p>
                            eloomi shall not be liable for loss of profits, business, earnings, revenue, anticipated
                            savings,
                            goodwill, loss of or damage to data, loss of use or value of any data or equipment,
                            including
                            software and wasted management, or for any other consequential or indirect loss whatsoever.
                            The maximum aggregate liability of eloomi for any and all claims, damages or losses
                            whatsoever
                            arising out of or in connection with the agreement, shall in no event exceed an amount
                            corresponding to 50 % of the charges paid by the Customer to eloomi for the first 12-month
                            subscription period. <br>
                            To the maximum extent permitted under the applicable law, eloomi disclaims any product
                            liability.
                        </p>
                    </li>
                    <li>
                        <label>AMENDMENTS </label><br>
                        <p>
                            eloomi may revise the terms and conditions of the agreement, by providing thirty (30) days'
                            prior
                            written notice to the customer eloomi will notify the Customer via an email to the email
                            associated with the purchase. If the Customer is unable to accept the revised terms and
                            conditions of the agreement, the Customer must notify eloomi in writing within fourteen (14)
                            days
                            of the notification by eloomi. If the Customer does not notify eloomi in accordance with the
                            foregoing, the revised terms and conditions shall be deemed accepted by the Customer and
                            take
                            effect at the expiry of the thirty (30) days' notice provided by eloomi.<br>
                            For the avoidance of doubt, eloomi may without separate notification and without the
                            acceptance
                            of the Customer from time to time change, amend and update any technical aspects, technical
                            specifications and/or technical requirements related to eloomi as well as any related
                            technical
                            and/or user documentation.
                        </p>
                    </li>
                    <li>
                        <label> ASSIGNMENT</label> <br>
                        <p>
                            The Customer shall not be entitled to assign its rights and obligations under the agreement
                            to a
                            third party without eloomi's express written consent. eloomi is entitled to assign its
                            rights
                            and
                            obligations under the agreement in whole or in part to a third party.
                        </p>
                    </li>
                    <li>
                        <label> CONFIDENTIALITY</label> <br>
                        <p>
                            The parties shall not, apart from what is required by applicable law or by any court or
                            other
                            authority of competent jurisdiction, make use of, except for the purposes contemplated by
                            this
                            agreement, disclose to any third party or publish any Confidential Information received by
                            one
                            Party from or in respect of the other Party under or in connection with this agreement. For
                            the
                            purpose of this Agreement, "Confidential Information" means a Party's commercial and
                            operational
                            information and knowhow and any other information not generally known or reasonably
                            ascertainable. The parties shall ensure that their employees also observe this clause.<br>
                            The provisions of this clause shall apply without limitation in time and shall survive any
                            termination of the agreement.
                        </p>
                    </li>
                    <li>
                        <label> CHOICE OF LAW AND VENUE</label> <br>
                        <p>
                            Any dispute must be determined in accordance with Danish Law (disregarding choice of law
                            rules
                            and CISG). Each party submits to the jurisdiction of the City Court of Copenhagen, Denmark,
                            as
                            the court of first instance. Any dispute will be carried out in Danish.
                        </p>
                    </li>
                </ol>
                <!-- <p>
                    1. The Intellectual Property disclosure will inform users that the contents, logo and other visual
                    media you created is your property and is protected by copyright laws.
                </p>
                <p>
                    2. A Termination clause will inform that users’ accounts on your website and mobile app or users’
                    access to your website and mobile (if users can’t have an account with you) can be terminated in
                    case of abuses or at your sole discretion.
                </p>
                <p>
                    3. A Governing Law will inform users which laws govern the agreement. This should the country in
                    which your company is headquartered or the country from which you operate your website and mobile
                    app.
                </p>
                <p>
                    4. A Links To Other Web Sites clause will inform users that you are not responsible for any third
                    party websites that you link to. This kind of clause will generally inform users that they are
                    responsible for reading and agreeing (or disagreeing) with the Terms and Conditions or Privacy
                    Policies of these third parties.
                </p>
                <p>
                    5. If your website or mobile app allows users to create content and make that content public to
                    other users, a Content section will inform users that they own the rights to the content they have
                    created. The “Content” clause usually mentions that users must give you (the website or mobile app
                    developer) a license so that you can share this content on your website/mobile app and to make it
                    available to other users.
                </p> -->


            </div>
            <div class="term-footer">
                <div class="text-center forget-password d-block">
                    <elm-button (click)="onAcceptClick()" size="large" color="primary" class="mr-5">Accept & Continue
                    </elm-button>
                    <elm-button (click)="onDeclineClick()" size="large" color="secondary">Cancel</elm-button>
                </div>
            </div>
        </div>
    </div>

</div>