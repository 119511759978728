import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LogoutRedirectComponent } from './components/logout-redirect/logout-redirect.component';
import { LoginSuccessCallbackComponent } from './components/login-success-callback/login-success-callback.component';
import { AuthRedirectLoginComponent } from './components/auth-redirect-login/auth-redirect-login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SessionGuard } from 'src/app/core/guards/session.guard';
import { LoginGuard } from 'src/app/core/guards/login.guard';



const routes: Routes = [
  {
    path: 'auth', children: [
      { path: 'login', pathMatch: 'full', component: LoginPageComponent, canActivate: [SessionGuard] },
      { path: 'logout-redirect', pathMatch: 'full', component: LogoutRedirectComponent, canActivate: [LoginGuard] },
      { path: 'login-success-callback', pathMatch: 'full', component: LoginSuccessCallbackComponent },
      { path: 'forgot-password', pathMatch: 'full', component: ForgotPasswordComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }

export const authRoutedComponents = [LoginPageComponent,
  LogoutRedirectComponent,
  LoginSuccessCallbackComponent,
  AuthRedirectLoginComponent,
  ForgotPasswordComponent];
