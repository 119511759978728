import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { InputTextModule } from 'primeng/inputtext';
import { AuthRedirectLoginComponent } from './components/auth-redirect-login/auth-redirect-login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginSuccessCallbackComponent } from './components/login-success-callback/login-success-callback.component';
import { LogoutRedirectComponent } from './components/logout-redirect/logout-redirect.component';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthRoutingModule, authRoutedComponents } from './auth-routing.module';
import { FormsModule } from '@angular/forms';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import '@eloomi/components';

@NgModule({
  declarations: [LoginPageComponent, AuthRedirectLoginComponent, ForgotPasswordComponent,
    LoginSuccessCallbackComponent, LogoutRedirectComponent, authRoutedComponents],
  imports: [
    CommonModule,
    FormsModule,
    ProgressSpinnerModule,
    InputTextModule,
    AuthRoutingModule
  ],
  exports: [LoginPageComponent],
  providers: [AuthService, JwtHelperService, ProfileService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule { }
