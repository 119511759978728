import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SideBarComponent } from './sidebar/side-bar.component';
import { AuthService } from './authentication/auth.service';
import { StorageService } from './services/storage.service';
import { TermsconditionComponent } from './termscondition/termscondition.component';
import { ProfileService } from './services/profile/profile.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FileSaverModule, FileSaverService } from 'ngx-filesaver';
import { RouterModule } from '@angular/router';
import { MediaLibraryService } from './services/mediaLibrary/media-library.service';
import { ProgressBarModule } from 'primeng/progressbar';
import '@eloomi/components';


@NgModule({
  declarations: [FooterComponent, HeaderComponent, SideBarComponent, TermsconditionComponent, ChangepasswordComponent],
  imports: [
    CommonModule, RouterModule, ClickOutsideModule, FormsModule, InputTextModule, ProgressSpinnerModule,
    FileSaverModule, ProgressBarModule
  ],
  exports: [FooterComponent, HeaderComponent, SideBarComponent],
  providers: [AuthService, StorageService, ProfileService, FileSaverService, MediaLibraryService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule { }
