import { environment } from 'src/environments/environment';
import { MediaLibraryItemType } from '../../models/media/mediaLibrary.model';

export class MediaLibraryUrls {
  static GET_MEDIA_FILES(mediaTypeId: any, searchQuery: string, pageIndex: any, pageSize: any): any {
    if (searchQuery.length > 0) {
      // tslint:disable-next-line:max-line-length
      return `${environment.liveUrl}/Media?mediaTypeId=${mediaTypeId}&searchquery=${searchQuery}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }
    return `${environment.liveUrl}/Media?mediaTypeId=${mediaTypeId}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
  }

  static GET_MEDIA_FILES_BY_COMPANY(mediaTypeId: number, companyId: number, searchQuery: string, pageIndex: number, pageSize: number): any {

    if (mediaTypeId === MediaLibraryItemType.Pexels.valueOf()) {
      if (searchQuery.length > 0) {
        return `${environment.pexelsUrl}/search?query=${searchQuery}&per_page=${pageSize}&page=${pageIndex}`;
      }  
      // return `${environment.pexelsUrl}/curated?page=${pageIndex}&per_page=${pageSize}`;
      return `${environment.pexelsUrl}/search?query=Work%20Office&per_page=${pageSize}&page=${pageIndex}`;
    }

    if (searchQuery.length > 0) {
      // tslint:disable-next-line:max-line-length
      return `${environment.liveUrl}/Media/${companyId}?mediaTypeId=${mediaTypeId}&searchquery=${searchQuery}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    }
    return `${environment.liveUrl}/Media/${companyId}?mediaTypeId=${mediaTypeId}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
  }

  static GET_COMPANY_STORAGE_USAGE(companyId: number): string {
    return `${environment.liveUrl}/Media/${companyId}/storage`;
  }

  static DELETE_MEDIA_FILE(mediaFileId: any): any {
    return `${environment.liveUrl}/Media/${mediaFileId}`;
  }

  static DELETE_MEDIA_FILE_BY_ID(companyId: number, mediaFileId: number): string {
    return `${environment.liveUrl}/storage/company/${companyId}/media/${mediaFileId}`;
  }
}
