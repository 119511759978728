import { Injectable } from '@angular/core';
import { WebAuth } from 'auth0-js';
import { BehaviorSubject, bindCallback, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth0Config, Roles } from '../constants/auth0-info.const';
import { LoginModel } from '../models/auth/Login.model';
import { StorageService } from '../services/storage.service';
import { UIService } from '../services/ui.service';
import { ProfileService } from '../services/profile/profile.service';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  webAuth: WebAuth;

  private ForgotEmail = new BehaviorSubject<string>('');
  cast = this.ForgotEmail.asObservable();
  refreshSubscription: any;

  constructor(private router: Router, private httpClient: HttpClient, private jwtHelper: JwtHelperService,
    private storageService: StorageService, private uiService: UIService, private profileService: ProfileService,
    private commonService: CommonService) {
    this.webAuth = new WebAuth({
      audience: Auth0Config.AUTH0_AUDIENCE,
      domain: Auth0Config.AUTH0_DOMAIN,
      clientID: Auth0Config.AUTH0_CLIENT_ID,
      redirectUri: window.location.origin + Auth0Config.AUTH0_LOGIN_SUCCESS_CALLBACK,
      responseType: Auth0Config.AUTH0_RESPONSE_TYPE,
      useRefreshTokens: true
    });

  }

  public login(loginModel: LoginModel) {
    const opts = {
      realm: Auth0Config.AUTH0_REALM,
      username: loginModel.email,
      password: loginModel.password,
      scope: Auth0Config.AUTH0_SCOPE
    };

    const loginAsObservable = bindCallback((
      crendetials: any,
      callback: (data: any) => void
    ) => this.webAuth.client.login(crendetials, callback));

    return loginAsObservable(opts);
  }
  // Sends out reset password email with onetime link
  public resetPassword(userEmailAddress: string) {

    const resetPasswordOpts = {
      connection: Auth0Config.AUTH0_REALM,
      email: userEmailAddress
    };

    const passwordResetAsObservable = bindCallback((
      passwordResetOpts: any,
      callback: (data: any) => void
    ) => this.webAuth.changePassword(passwordResetOpts, callback));

    return passwordResetAsObservable(resetPasswordOpts);
  }

  public loginWithRedirect() {
    this.webAuth.authorize({
      connection: Auth0Config.AUTH0_REALM,
      redirectUri: window.location.origin + Auth0Config.AUTH0_LOGIN_SUCCESS_CALLBACK
    });
  }
  public logout() {
    this.unscheduleRenewal();

    (window as any).global = window;

    this.webAuth.logout({
      returnTo: window.location.origin,
    });
  }
  public getAuthTokenFromStorage(): string {
    return this.storageService.getValue(Auth0Config.AUTH0_ACESS_TOKEN_NAME);
  }

  public isAuthenticated(): boolean {
    const authToken = this.getAuthTokenFromStorage();

    if (authToken) {
      if (this.jwtHelper.isTokenExpired(authToken)) {
        this.storageService.clearStorage();
        return false;
      }
      return true;
    }

    return false;
  }

  public isInRole(role: string): boolean {
    const authToken = this.getAuthTokenFromStorage();

    if (authToken) {
      const decodedToken = this.jwtHelper.decodeToken(authToken);
      return decodedToken['https://tspoon.dk/roles'].indexOf(role) > -1;
    }

    return false;
  }

  public decodeAuthToken(): any {
    if (this.isAuthenticated()) {
      const authToken = this.getAuthTokenFromStorage();
      return this.jwtHelper.decodeToken(authToken);
    }

    return null;
  }

  public renewToken() {
    this.webAuth.checkSession({}, (err, result) => {
      if (!err) {
        this.processLogin(result, true);
      } else {
        this.router.navigate(['/auth/logout-redirect']);
      }
    });
  }

  public processLogin(authResult: any, isRefresh: boolean) {

    const accessToken: string = authResult.accessToken;

    if (authResult.idToken) {

      this.storageService.setValue(Auth0Config.AUTH0_ACESS_TOKEN_NAME, authResult.idToken);
      const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
      this.storageService.setValue(Auth0Config.AUTH0_EXPIRES_AT, expiresAt);
      this.scheduleRenewal();

      if (!isRefresh) {

        this.webAuth.client.userInfo(accessToken, (err, user) => {

          this.profileService.getProfileByEmail(user.email).subscribe(
            result => {
              if (result) {
                const decodedToken = this.jwtHelper.decodeToken(authResult.idToken);
                result.isAdmin = decodedToken['https://tspoon.dk/roles'].indexOf(Roles.Admin) > -1;
                result.imageUrl = this.commonService.getImage(result);
                this.storageService.appUser = result;

                this.initSession();
              }
            });
          user.externalUserId = authResult.externalUserId;
          user.provider = authResult.provider;
        });
      }
    } else {
      this.router.navigate(['/auth/logout-redirect']);
    }
  }

  public parseSocialIdentityAuthResponse(parseWebAuthHashCallback) {
    this.webAuth.parseHash(parseWebAuthHashCallback);
  }
  private initSession() {
    if (this.storageService.appUser) {
      if (!this.storageService.appUser.initialPasswordReset) {
        this.router.navigate([`/change-password`]);
      } else if (!this.storageService.appUser.agreedTermsAndConditions) {
        this.router.navigate([`/contract`]);
      } else {
        this.uiService.changeSession(true);
        if (this.storageService.appUser.isAdmin) {
          this.router.navigate([`/dashboard`]);
        } else {
          this.router.navigate([`/courses`]);
        }
      }
    }
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) return;

    const expiresAt = JSON.parse(this.storageService.getValue(Auth0Config.AUTH0_EXPIRES_AT));

    const source = of(expiresAt).pipe(mergeMap(
      expiresAt => {

        const now = Date.now();

        var refreshAt = expiresAt - (1000 * 60);
        return timer(Math.max(1, refreshAt - now));
      }));

    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
    });
  }

  public unscheduleRenewal() {
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }

}
