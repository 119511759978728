import { Component, OnInit, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from './core/authentication/auth.service';
import { UIService } from './core/services/ui.service';
import { ToastService } from './core/services/toast.service';
import { ToastModel } from './core/models/toast.model';
import { StorageService } from './core/services/storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Intercom } from 'ng-intercom';
import { ProfileService } from './core/services/profile/profile.service';

declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnChanges {

  title = 'eloomi-app';

  stylesPath: SafeResourceUrl;
  stylesPathDev: SafeResourceUrl;

  isLogin = false;
  toastModel: ToastModel;

  isTermsAndConditionAccepted = false;
  isCourseLoading = false;
  locationUrl: any;

  constructor(private uiService: UIService, private sanitizer: DomSanitizer, private authService: AuthService,
              private toastService: ToastService, public storageService: StorageService, 
              public router: Router, public intercom: Intercom, public profileService: ProfileService) {
    this.uiService
      .sessionChanged$
      .subscribe(status => {
        this.isLogin = status;
      });
    this.locationUrl = window;
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     gtag('config', 'UA-170100800-2',
    //       {
    //         page_path: event.urlAfterRedirects
    //       }
    //     );
    //   }
    // });

    
  }
  async ngOnInit() {
    this.isUserLoggedIn();
    this.subscribeToast();
    // this.initializeDesigns();
    // this.uiService.onCourseLoad$
    //   .subscribe(result =>
    //     this.isCourseLoading = result);

    if (this.storageService.appUser && this.storageService.appUser.id && window.location.href.toLowerCase().indexOf('lms-preview') === -1) {
      this.initiateIntercom();
    }

    this.uiService.sessionChanged$.subscribe(x => {
      if (x && window.location.href.toLowerCase().indexOf('lms-preview') === -1) {
        this.initiateIntercom();      
      }
    })
  }

  

  initiateIntercom() {

    const appUser = this.storageService.appUser;

    if (appUser.userHash && appUser.userHash.length > 0) {
      this.intercomSettings(appUser);
    } else {
      this.profileService.getUserHash(this.getUserId(appUser.id)).subscribe(x => {
        appUser.userHash = x;
        this.intercomSettings(appUser);
      })
    }
  }

  private getUserId(id){
    return `authoring:${id}`
  }

  private intercomSettings(appUser: any) {
    
    this.intercom.boot({
      app_id: environment.intercomAppId,
      // Supports all optional configuration.
      name: appUser.firstName,
      email: appUser.emailAddress,
      user_id: this.getUserId(appUser.id),
      user_hash: appUser.userHash,
      created_at: Date.now(),
      widget: {
        "activator": "#intercom"
      }
    });
  }

  initializeDesigns() {
    // this.stylesPath = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.assetsRoot}/styles/style.css`);
    // this.stylesPathDev = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.assetsRootDev}/styles/style.css`);
  }
  isUserLoggedIn() {
    if (this.authService.isAuthenticated()) {
      this.uiService.changeSession(true);
    } else {
      this.uiService.changeSession(false);
    }
  }
  private subscribeToast() {
    this.toastService.toastChanged$.subscribe(x => {
      this.toastModel = x;
    });
  }
  ngOnChanges() {
    this.isTermsAndConditionAccepted = this.storageService.appUser && this.storageService.appUser.agreedTermsAndConditions;
  }
}
