import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../authentication/auth.service';
import { StorageService } from '../services/storage.service';
import { UIService } from '../services/ui.service';
import { Roles } from '../constants/auth0-info.const';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private storageService: StorageService,
              private uiService: UIService) {
  }

  canActivate() {

    if (this.isLogin() && this.authService.isInRole(Roles.Admin)) {
      return true;
    }

    this.router.navigate(['/auth/login']);

    return false;
  }

  private isLogin() {
    if (this.authService.isAuthenticated() && this.storageService.appUser != null) {
      if (!this.storageService.appUser.initialPasswordReset) {
        this.router.navigate([`/change-password`]);
      } else if (!this.storageService.appUser.agreedTermsAndConditions) {
        this.router.navigate([`/contract`]);
      } else {
        return true;
      }
      return false;
    } else {
      this.uiService.changeSession(false);
      return false;
    }
  }
}
