import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './core/guards/login.guard';
import { TermsconditionComponent } from './core/termscondition/termscondition.component';
import { MemberGuard } from './core/guards/member.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { ChangepasswordComponent } from './core/changepassword/changepassword.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  { path: 'dashboard', loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [MemberGuard] },
  { path: 'auth', loadChildren: () => import('src/app/modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'setting', loadChildren: () => import('src/app/modules/setting/setting.module').then(m => m.SettingModule), canActivate: [AdminGuard] },
  { path: 'contract', pathMatch: 'full', component: TermsconditionComponent, canActivate: [LoginGuard] },
  { path: 'change-password', pathMatch: 'full', component: ChangepasswordComponent, canActivate: [LoginGuard] },
  { path: 'help', loadChildren: () => import('src/app/modules/help/help.module').then(m => m.HelpModule), canActivate: [LoginGuard] },
  { path: 'profile', loadChildren: () => import('src/app/modules/profile/profile.module').then(m => m.ProfileModule), canActivate: [LoginGuard] },
  { path: 'client-stats', loadChildren: () => import('src/app/modules/client-stats/client-stats.module').then(m => m.ClientStatsModule), canActivate: [LoginGuard] },
  { path: 'stats', loadChildren: () => import('src/app/modules/admin-stats/admin-stats.module').then(m => m.AdminStatsModule), canActivate: [AdminGuard] },
  {
    path: 'accountmanagement', loadChildren: () => import('src/app/modules/accountmanagement/accountmanagement.module').then(m => m.AccountmanagementModule),
    canActivate: [AdminGuard]
  },
  { path: 'courses', loadChildren: () => import('src/app/modules/design-course/design-course.module').then(m => m.DesignCourseModule), canActivate: [MemberGuard] },
  { path: 'media', loadChildren: () => import('src/app/modules/media-library/media-library.module').then(m => m.MediaLibraryModule), canActivate: [MemberGuard] },
  { path: 'preview', loadChildren: () => import('src/app/modules/preview-course/preview-course.module').then(m => m.PreviewCourseModule), canActivate: [MemberGuard] },
  { path: 'course', loadChildren: () => import('src/app/modules/design-course/design-course.module').then(m => m.DesignCourseModule), canActivate: [MemberGuard] },
  { path: 'scormContentAPI', loadChildren: () => import('src/app/modules/scorm/scorm.module').then(m => m.ScormModule), canActivate: [] },
  {
    path: 'lms-preview',
    loadChildren: () => import('src/app/modules/lms-preview-course/lms-preview-course.module').then(m => m.LMSPreviewCourseModule), canActivate: []
  },
  {
    path: 'company-settings',
    loadChildren: () => import('src/app/modules/company-design-settings/company-design-settings.module').then(m => m.CompanyDesignSettingsModule),
    canActivate: [LoginGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
