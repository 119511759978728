import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  private localName = 'appUser';
  private tempName = 'tempValue';
  private courseName = '';

  constructor() { }

  set appUser(userModel: any) {
    localStorage.setItem(this.localName, JSON.stringify(userModel));
  }

  get appUser(): any {
    return JSON.parse(localStorage.getItem(this.localName));
  }

  set tempValue(value: any) {
    localStorage.setItem(this.tempName, value);
  }

  get tempValue(): any {
    const val = localStorage.getItem(this.tempName);

    localStorage.removeItem(this.tempName);

    return val;
  }

  setValue(key: string, data: string) {
    localStorage.setItem(key, data);
  }

  getValue(key: string): string {
    return localStorage.getItem(key);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
  clearStorage() {
    localStorage.clear();
  }
}
