import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, Observable } from 'rxjs';
import { ContentVariantType, DividerSettingType } from '../models/course/contentBlock.model';
import { DOCUMENT } from '@angular/common';
import { PageModel } from '../models/course/page.model';

@Injectable()
export class CommonService {

  directoryPath = '';
  units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  private courseProgress = new Subject<any>();
  private pageProgress = new Subject<any>();
  private pageProgressUpdate = new Subject<any>();
  private blockProgress = new Subject<any>();

  constructor() { }
  onModalClose() {
    document.body.classList.remove('modal-open');
  }
  onModalOpen() {
    document.body.classList.add('modal-open');
  }
  getDirectoryFullPath(fileName: any = '') {
    if (fileName && fileName.length > 0) {
      return environment.production ? `${environment.imagesFolder}/${fileName}` : `${environment.imagesFolderDev}/${fileName}`;
    }
    return environment.production ? environment.imagesFolder : environment.imagesFolderDev;
  }
  getDefaultImage() {
    return environment.production ? `${environment.imagesFolder}/default-user.png` : `${environment.imagesFolderDev}/default-user.png`;
  }
  getImage(item: any): string {
    let imagePath = '';

    if (item.imageUrl != null || item.avatarUrl != null) {
      if (item.isImage) {
        imagePath = item.imageUrl;
      } else {
        if (item.avatarUrl) {
          imagePath = this.getDirectoryFullPath(item.avatarUrl);
        } else {
          imagePath = item.imageUrl;
        }

      }
    } else {
      imagePath = this.getDefaultImage();
    }
    return imagePath;
  }
  getCkEditorConfigurationForParagraph(placeHolder = ''): any {
    const config = {
      height: '320px', width: '100%',
      font_defaultLabel: 'Times New Roman',
      fontSize_defaultLabel: '12px',
      toolbarCanCollapse : true,
      readOnly: false,
      toolbar: {
        items: [
          'heading',
          '|',
          'fontsize',
          'fontcolor',
          'fontbackgroundcolor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'highlight',
          '|',
          'alignment',
          '|',
          'numberedList',
          'bulletedList',
          '|',
          'outdent',
          'indent',
          'link',
          'blockquote',
          'insertTable',
          '|',
          'undo',
          'redo'
        ]
      },
      link : {
        addTargetToExternalLinks: true
      },
      placeholder: placeHolder
    };
    return config;
  }
  getCkEditorConfigurationForQuote(placeHolder = ''): any {
    const config = {
      height: '320px', width: '100%',
      font_defaultLabel: 'Times New Roman',
      fontSize_defaultLabel: '12px',
      toolbarCanCollapse : true,
      readOnly: false, toolbar: {
        items: [
          'fontsize',
          'fontcolor',
          'fontbackgroundcolor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'highlight',
          '|',
          'alignment',
          '|',
          'undo',
          'redo'
        ]
      },
      placeholder: placeHolder,
      wordcount: {
        maxCharCount: 500
      },
      maxlength: 500
    };
    return config;
  }
  getCkEditorConfigurationForImage(): any {
    const config = {
      height: '320px', width: '100%', toolbar: {
        items: [
          'alignment',
          'imageUpload',
          'undo',
          'redo',
        ]
      }
    };
    return config;
  }

  getCkEditorConfigurationForParagraphAndImage(): any {
    const config = {
      height: '320px', width: '100%',
      toolbar: {
        items: [
          'heading',
          '|',
          'alignment',
          'FontSize',
          'Underline',
          'FontColor',
          'FontBackgroundColor',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'Indent',
          'blockQuote',
          'undo',
          'redo',
        ]
      },
      link : {
        addTargetToExternalLinks: true
      }
    };
    return config;
  }
  getDefaultThumbnailForCourse() {
    return '../../../../../assets/images/course-2.png';
  }
  getParagraphOneDefaultText() {
    return `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque suscipit neque ac erat vehicula tristique.
 Etiam ullamcorper ligula non enim ornare, sit amet rutrum dolor commodo. Aenean pellentesque ut ante sodales tristique.
 Mauris ac massa laoreet, posuere ligula in, egestas risus. Aliquam quis lorem vulputate, cursus arcu eu, luctus lacus.
  Morbi condimentum sapien finibus felis tristique, id finibus nibh aliquet. In metus mi, scelerisque in est sit amet,
   faucibus volutpat risus. Donec ullamcorper nibh vel risus hendrerit malesuada a non sapien. Nam dignissim felis
non mauris pellentesque interdum. Sed turpis erat, fringilla sit amet ornare quis, condimentum quis nisl.
 Proin non eros a metus finibus rhoncus quis quis odio</p>`;
  }
  getParagraphTwoDefaultText() {
    return `<p>Proin arcu ligula, elementum ut augue sed, tincidunt lobortis massa. Ut lacinia eleifend nibh, eu tincidunt
     elit semper sed. Aenean id ligula consectetur, viverra massa nec, tincidunt leo. Aenean nisi tellus, eleifend nec auctor et,
      porta eget purus. Phasellus metus felis, viverra in aliquam non, ornare fringilla arcu. Maecenas eu velit et erat volutpat egestas.
       Duis et efficitur metus, sed dictum leo. Praesent viverra ipsum nec semper mollis. Duis sagittis id orci a euismod.
        Integer ipsum turpis,hendrerit in tempor sed, dictum ullamcorper lectus.</p>`;
  }
  getNoteDefaultText() {
    return `Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque suscipit neque ac erat vehicula tristique.`;
  }
  getListItemDefaultText() {
    return `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;
  }
  getDefaultImageColumn1() {
    return `../../../../../assets/images/course-default-image-col1.jpg`;
  }
  getDefaultImageColumn2() {
    return `../../../../../assets/images/course-default-image-col2.jpg`;
  }
  getDefaultImageCentered() {
    return `../../../../../assets/images/course-default-image-centered.jpg`;
  }
  getDefaultImageFull() {
    return `../../../../../assets/images/course-default-image-full.jpg`;
  }
  getDefaultTextForImage() {
    return `lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque suscipit neque ac erat vehicula tristique.
    Etiam ullamcorper ligula non enim ornare, sit amet rutrum dolor commodo. Aenean pellentesque ut ante sodales tristique.
    Mauris ac massa laoreet`;
  }
  getDefaultAudioPath() {
    return `../../../../../assets/audio/TSPOON-placeholder-audio.mp3`;
  }
  getDefaultVideoPath() {
    return `https://www.youtube.com/watch?v=iUtnZpzkbG8`;
  }
  getDefaultWriter(choice) {
    switch (choice) {
      case ContentVariantType.Quote_A:
        return 'Daniel Mørch';
      case ContentVariantType.Quote_B:
        return 'Kim Brabrand';
    }
  }
  getDefaultQuote() {
    return `Lorem ipsum dolor sit amet, consectetur adipiscing elit.Pellentesque suscipit neque ac erat vehicula tristique.
    Etiam ullamcorper ligula non enim ornare, sit amet rutrum dolor commodo. Aenean pellentesque ut ante sodales tristique.`;
  }
  getDefaultWriterImage(choice) {
    switch (choice) {
      case ContentVariantType.Quote_A:
        return '../../../../../assets/images/Daniel-Mørch.png';
      case ContentVariantType.Quote_B:
        return '../../../../../assets/images/Kim-Brabrand.png';
    }
  }
  getQuoteFontFamily(choice) {
    switch (choice) {
      case ContentVariantType.Quote_A:
        return 'Roboto';
      case ContentVariantType.Quote_B:
        return 'Verdana';
    }
  }
  updateCourseProgress(percentageIncrement: any) {
    this.courseProgress.next({ percentage: percentageIncrement });
  }
  getCourseProgress(): Observable<any> {
    return this.courseProgress.asObservable();
  }
  updatePageProgress(isLastBlock: boolean, pagePercentageIncrement: any, coursePercentageIncrement: any, pageId: any,
                     blockId: any, quizPercentage = 0, quizRetries = 0) {
    this.pageProgress.next({ percentage: pagePercentageIncrement, pageId, blockId, quizPercentage, quizRetries });
    if (isLastBlock) {
      this.updateCourseProgress(coursePercentageIncrement);
    }
  }

  updateScore(score: number, max: number = 100, min: number = 0) {
    if (window.parent["RXD"]) {
      window.parent["RXD"].SetScore(score, max, min);
    }
  }

  getPageProgress(): Observable<any> {
    return this.pageProgress.asObservable();
  }
  setPageProgressUpdate(pages: PageModel[]) {
    this.pageProgressUpdate.next({ coursePages: pages });
  }
  getPageProgressUpdate(): Observable<any> {
    return this.pageProgressUpdate.asObservable();
  }

  updateBlockProgress(blockId) {
    this.blockProgress.next({blockId});
  }
  getBlockProgress(): Observable<any> {
    return this.blockProgress.asObservable();
  }
  getUnit(x) {

    let l = 0;
    let n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    // include a decimal point and a tenths-place digit if presenting
    // less than ten of KB or greater units
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + this.units[l]);
  }
  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  getDividerButtonDefaultText(type: DividerSettingType) {
    if (type === DividerSettingType.CompleteAll) {
      return 'You have to complete everything above before you continue';
    } else if (type === DividerSettingType.CompleteDirectlyAbove) {
      return 'You have to complete the content above before you continue';
    }
    return '';
  }
  scrollToElementByID(elementId: string, delayTimeInSeconds = 0) {
    setTimeout( () => {
      document.getElementById(elementId).scrollIntoView();
    }, 100 * delayTimeInSeconds);
  }
  scrollInModalBodyByPixels(pixels = 100, delayTimeInSeconds = 0, bottomFactorForQuiz = 100) {
    setTimeout( () => {
      // For Mobile View Screen
      const mobItem = document.getElementsByClassName('risponsive-class');
      const isDesktopView = document.getElementsByClassName('Tablet_Portrait').length === 0 &&
                            document.getElementsByClassName('Tablet_Landscape').length === 0 &&
                            document.getElementsByClassName('Mobile_Portrait').length === 0 &&
                            document.getElementsByClassName('Mobile_Landscape').length === 0;
      const quizItem = document.getElementsByTagName('app-preview-quiz');
      bottomFactorForQuiz = document.getElementsByClassName('footer-navigation').length === 0 ? 0 : 100;
      if (mobItem.length > 0 && !isDesktopView) {
        if (quizItem.length > 0) {
          mobItem[0].scrollTo(0, (mobItem[0].scrollHeight - mobItem[0].clientHeight) - bottomFactorForQuiz);
        } else {
          mobItem[0].scrollTo(0, mobItem[0].scrollTop + 150);
        }
      } else {
        const item = document.getElementsByClassName('modal-body');
        if (item.length > 1) {
          if (quizItem.length > 0) {
            item[1].scrollTo(0, (item[1].scrollHeight - item[1].clientHeight) - bottomFactorForQuiz);
          } else {
          item[1].scrollBy(0, pixels);
          }
        } else if (item.length === 1) {
          if (quizItem.length > 0) {
            item[0].scrollTo(0, (item[0].scrollHeight - item[0].clientHeight) - bottomFactorForQuiz);
          } else {
          item[0].scrollBy(0, pixels);
          }
        }
      }
    }, 100 * delayTimeInSeconds);
  }
  scrollModalBodyToTop() {
    setTimeout( () => {
      const item = document.getElementsByClassName('modal-body');
      if (item.length > 1) {
        document.getElementsByClassName('modal-body')[1].scrollTo(0, 0);
      } else if (item.length === 1) {
        document.getElementsByClassName('modal-body')[0].scrollBy(0, 0);
      }
    }, 100 * 1);
  }
}
