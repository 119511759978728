<div class="login-main" [hidden]="isLoggedIn" [class.mobilePadding]="isMobile">
  <div class="top-nav-login" *ngIf="!isMobile">
    <a>
      <img width="200" src="../../../../../assets/icons/svg/logo-2.svg">
    </a>
  </div>
  <div class="login-wraper" *ngIf="!isMobile">
    <div class="login-inner-wraper">
      <div class="notification-error" *ngIf="invalidEmailorPassword">
          <span class="notification-icon">
          <span class="gt-icon gt-icon-error"></span>
          </span>
          <span class="gt-t">{{validationMessage}}</span>
       </div>
      <span class="gt-t1 inner-title full-width mb-4">Login with Email address</span>
      <span class="p-float-label">
        <elm-text-field type="email" maxlength="200" [(ngModel)]="model.email" size="large" autofocus="true" placeholder="E-mail" #email 
        [color]="email.classList.contains('ng-dirty') && model.email.length === 0 ? 'danger' : 'default'" ngDefaultControl></elm-text-field>
        
      </span>
      <span class="p-float-label mb-3">
        <elm-text-field type="password" maxlength="30" [(ngModel)]="model.password" size="large" placeholder="Password" ngDefaultControl #password
        [color]="password.classList.contains('ng-dirty') && model.password.length === 0 ? 'danger' : 'default'"></elm-text-field>
      </span>
      <span class="remember-password" (click)="onForgotPasswordClick()">Don’t remember your password?</span>
      <div class="mt-5">
          <elm-button (click)="onSubmitClick()" size="large" color="primary" [disabled]="model.email.length === 0 || model.password.length === 0">LOGIN</elm-button>
      </div>
    </div>

  </div>
  <div *ngIf="isMobile">
    <div class="image-content">
      <img src="../../../../../assets/icons/svg/miscellaneous-desktop-smiley.svg">
    </div>
    <p class="txt-desktop">Only available on desktop</p>
    <p class="txt-access">Please login from desktop to access eloomi Authoring</p>
  </div>

</div>
<p-progressSpinner *ngIf="isAsyncCall"></p-progressSpinner>
