import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileUrls } from '../../constants/profile/profileURLs';
import { CreateProfile, ProfileImageModel } from '../../models/profile/createProfile.model';
import { ProfileModel } from '../../models/profile/profile.model';
import { ChangePasswordModel } from '../../models/auth/Login.model';

@Injectable()
export class ProfileService {

  headers: HttpHeaders;

  constructor(private httpClient: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers.append('contentType', 'application/json');
  }

  createProfile(objProfile: CreateProfile): Observable<ProfileModel> {
    return this.httpClient
      .post(ProfileUrls.CREATE_PROFILE(), objProfile, { headers: this.headers })
      .pipe(map(res => <ProfileModel> (res)));
  }
  updateProfile(objProfile: CreateProfile, userId: any): Observable<ProfileModel> {
    return this.httpClient
      .put(ProfileUrls.UPDATE_PROFILE(userId), objProfile, { headers: this.headers })
      .pipe(map(res => <ProfileModel> (res)));
  }
  getProfileList(isAdmin: boolean, searchQuery: string, pageIndex: any, pageSize: any): Observable<Array<ProfileModel>> {
    return this.httpClient
      .get(ProfileUrls.GET_PROFILE_LIST(isAdmin, searchQuery, pageIndex, pageSize), { headers: this.headers })
      .pipe(map(res => <Array<ProfileModel>> (res)));
  }
  getProfileById(id): Observable<ProfileModel> {
    return this.httpClient
      .get(ProfileUrls.GET_PROFILE_BY_ID(id), { headers: this.headers })
      .pipe(map(res => <ProfileModel> (res)));
  }
  getProfileByEmail(email): Observable<ProfileModel> {
    return this.httpClient
      .get(ProfileUrls.GET_PROFILE_BY_EMAIL(email), { headers: this.headers })
      .pipe(map(res => <ProfileModel> (res)));
  }
  getUserHash(message: string): Observable<string> {
    return this.httpClient
      .get(ProfileUrls.GET_USER_HASH(message), { headers: this.headers })
      .pipe(map(res => <string> (res)));
  }
  sendpasswordResestEmail(objPasswordReset: any) {
    return this.httpClient
      .post(ProfileUrls.PASSWORD_RESET_EMAIL(), objPasswordReset, { headers: this.headers })
      .pipe(map(res => <any> (res)));
  }
  getResetPasswordRequest(uniqueId: any): Observable<any> {
    return this.httpClient
      .get(ProfileUrls.GET_PASSWORD_RESET_REQUEST(uniqueId), { headers: this.headers })
      .pipe(map(res => <any> (res)));
  }
  resetPassword(objResetPassword: any): Observable<any> {
    return this.httpClient
      .post(ProfileUrls.RESET_PASSWORD(), objResetPassword, { headers: this.headers })
      .pipe(map(res => <any> (res)));
  }
  markTermsAndCondition(userId: any): Observable<any> {
    return this.httpClient
      .get(ProfileUrls.MARK_TERMS_CONDITION(userId), { headers: this.headers })
      .pipe(map(res => <any> (res)));
  }
  changePassword(userId: any, passwordModel: ChangePasswordModel): Observable<any> {
    return this.httpClient
      .put(ProfileUrls.CHANGE_PASSWORD(userId), passwordModel, { headers: this.headers })
      .pipe(map(res => <any> (res)));
  }
  uploadProfileImage(userId: any, imageModel: ProfileImageModel): Observable<any> {
    return this.httpClient
      .put(ProfileUrls.UPLOAD_PROFILE_IMAGE(userId), imageModel, { headers: this.headers })
      .pipe(map(res => <any> (res)));
  }
  updateProfileStatus(userId: any, isBlocked: any, externalId: any): Observable<any> {
    return this.httpClient
      .put(ProfileUrls.UPDATE_PROFILE_STATUS(userId), { externalId, isBlocked }, { headers: this.headers })
      .pipe(map(res => <any> (res)));
  }
  deleteProfile(userId: number, externalId: string): Observable<any> {
    return this.httpClient
      .delete(ProfileUrls.DELETE_PROFILE(userId, externalId), { headers: this.headers })
      .pipe(map(res => <any> (res)));
  }
  getProfileCount(): Observable<number> {
    return this.httpClient
      .get(ProfileUrls.GET_PROFILE_COUNT(), { headers: this.headers })
      .pipe(map(res => <number> (res)));
  }
}
