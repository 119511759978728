import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { Auth0Config } from './core/constants/auth0-info.const';
import { SessionGuard } from './core/guards/session.guard';
import { LoginGuard } from './core/guards/login.guard';
import { AuthModule } from './modules/auth/auth.module';
import { UIService } from './core/services/ui.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastService } from './core/services/toast.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MemberGuard } from './core/guards/member.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { ClickOutsideModule } from 'ng-click-outside';
import { CommonService } from './core/services/common.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { IntercomModule } from 'ng-intercom';
import { HttpClientErrorInterceptor } from './core/authentication/http-client-error.interceptor';


export function localStorageTokenGetter(): string {
  const token = localStorage.getItem(Auth0Config.AUTH0_ACESS_TOKEN_NAME);

  // const helper = new JwtHelperService();

  // const decodedToken = helper.decodeToken(token);
  // const expirationDate = helper.getTokenExpirationDate(token);
  // const isExpired = helper.isTokenExpired(token);

  return token;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthModule,
    BrowserAnimationsModule,
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: localStorageTokenGetter,
        whitelistedDomains: [
          'localhost:4201',
          'localhost:44319',
          'eloomi-authoring-service.azurewebsites.net',
          'eloomi-authoring-service-prod.azurewebsites.net',
          'cloud.scorm.com',
        ]
      },
    }
    ),
    ClickOutsideModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpClientErrorInterceptor, multi: true },
    LoginGuard, SessionGuard, UIService, ToastService, MemberGuard, AdminGuard, CommonService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
