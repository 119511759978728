export class Auth0Config {
  static AUTH0_DOMAIN = 'dev-fgregpc8.eu.auth0.com';
  static AUTH0_CLIENT_ID = 'hHaoZf0B7neU80zAns2tvdAZn55NZ7sv';
  static AUTH0_RESPONSE_TYPE = 'token id_token';
  static AUTH0_REALM = 'Username-Password-Authentication';
  static AUTH0_ACESS_TOKEN_NAME = 'eloomi-Auth-Token';
  static AUTH0_EXPIRES_AT = 'eloomi_expires_at';
  static AUTH0_SCOPE = 'openid profile email birthdate';
  static AUTH0_AUDIENCE = 'https://grape-dev.identity.com';
  static AUTH0_LOGIN_SUCCESS_CALLBACK = '/auth/login-success-callback';
}

export class Roles {
  static Admin  = 'Admin';
}
