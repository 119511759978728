<div class="top-bar">
  <a routerLink="/dashboard"><img width="60" src="../../../assets/images/logo-2.png"></a>
  <span class="top-text" *ngIf="router.url.indexOf('dashboard') > -1"><strong>Hello {{storageService.appUser.firstName}},</strong> welcome to your dashboard</span>
  
  
  <div class="right-menu">
    <ul class="icons-main">
      <!-- Commented as their functionalty will be done in post MVP  -->
      <!--
        <li><a href="/help"><span class="gt-icon gt-icon-help"></span></a></li>

       <li><a href="javascrpt:;"><span class="gt-icon gt-icon-notification"></span></a></li>
      <li><a href="javascrpt:;"><span class="gt-icon gt-icon-search"></span></a></li> -->
    </ul>
    <div class="profile-top" [class.clint-user]="!storageService.appUser.isAdmin" (clickOutside)="onClickOutSide($event)">
      <div class="profile-top-main" (click)="openDropdown();">
        <div class="profile-image">
            <img [src]="storageService.appUser.imageUrl">
        </div>

        <span class="gt-t3">{{storageService.appUser.firstName}} {{storageService.appUser.lastName}}</span>
      </div>
      <div class="dropdown-topbar" [class.open-menu]="openMenu">
        <ul>
          <li><a (click)="navigateTo('/profile/edit')">MY PROFILE SETTINGS</a></li>
          <li *ngIf="storageService.appUser && !storageService.appUser.isAdmin"><a (click)="navigateTo('/company-settings')">Company Settings</a></li>
          <li *ngIf="storageService.appUser && storageService.appUser.isAdmin"><a (click)="navigateTo('/profile/usermanagment')">Admin Management</a></li>
          <li *ngIf="storageService.appUser && storageService.appUser.isAdmin"><a (click)="navigateTo('/accountmanagement')">Client Management</a></li>
          <li *ngIf="storageService.appUser && storageService.appUser.isAdmin"><a (click)="navigateTo('/setting')" >Platform Settings</a></li>
          <li (click)="handleLogOut()"><a>Log out</a></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="storage-progress" *ngIf="storageConsumed && storageLimit">
    <span class="storage-details">Storage <span class="float-right">{{storageConsumed}} of {{storageLimit}} {{storageUnitName}}</span></span>
    <p-progressBar [value]="consumedStorageTbValue"></p-progressBar>
  </div>
</div>
