import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Router } from '@angular/router';
import { Auth0Config } from 'src/app/core/constants/auth0-info.const';

@Component({
  selector: 'app-login-success-callback',
  templateUrl: './login-success-callback.component.html',
  styleUrls: ['./login-success-callback.component.less']
})
export class LoginSuccessCallbackComponent implements OnInit {

  constructor(private storageService: StorageService, private authService: AuthService, private router: Router) {

  }

  ngOnInit() {
    const self = this;

    const parseWebAuthHashCallback = (err, authResult) => {
      if (!err && authResult) {

        if (authResult.idTokenPayload) {
          const subParts = authResult.idTokenPayload.sub.split('|');

          if (subParts.length > 1) {
            authResult.externalUserId = subParts[1];
            authResult.provider = subParts[0];

            this.storageService.setValue(Auth0Config.AUTH0_ACESS_TOKEN_NAME, authResult.accessToken);
            self.authService.processLogin(authResult, false);
          }
        }
      }
    };
    self.authService.parseSocialIdentityAuthResponse(parseWebAuthHashCallback);
  }

}
