export class LoginModel {
  constructor() {
    this.email = '';
    this.password = '';
  }
  email: string;
  password: string;
  rememberMe = false;
}
export class ChangePasswordModel {
  emailAddress: string;
  externalUserId: string;
  oldPassword: string;
  newPassword: string;
}
