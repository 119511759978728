import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../services/profile/profile.service';
import { StorageService } from '../services/storage.service';
import { ChangePasswordModel } from '../models/auth/Login.model';
import { ToastModel } from '../models/toast.model';
import { ToastService } from '../services/toast.service';
import { UIService } from '../services/ui.service';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.less']
})
export class ChangepasswordComponent {
  isAsyncCall: boolean;
  oldPassword: string;
  newPassword: string;
  validationMessage: string;
  invalidPassword: boolean;
  showOldPassword: boolean;
  showPassword: boolean;
  uppercasePassword: boolean;
  lowercasePassword: boolean;
  numberPassword: boolean;
  showPasswordStrength: boolean;
  hoverOldPassword: boolean;
  isChangePasswordClicked: boolean;
  errorCount: number;

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
      if (this.errorCount === 0 && this.oldPassword.length > 0 && this.newPassword.length > 0) {
        this.changePassword();
      }
    }

  constructor(private router: Router, private profileService: ProfileService, private storageService: StorageService,
              private toastService: ToastService, private uiService: UIService) {
                if (this.storageService.appUser.initialPasswordReset) {
                  this.router.navigate([`/contract`]);
                }
                this.defaultValues();
   }

   defaultValues() {
    this.isAsyncCall = false;
    this.invalidPassword = false;
    this.showOldPassword = false;
    this.showPassword = false;
    this.uppercasePassword = false;
    this.lowercasePassword = false;
    this.numberPassword = false;
    this.showPasswordStrength = false;
    this.oldPassword = '';
    this.newPassword = '';
    this.hoverOldPassword = false;
    this.isChangePasswordClicked = false;
    this.errorCount = 0;
   }

  onCancel() {
    this.router.navigate(['/auth/logout-redirect']);
  }

  changePassword() {

    if (this.errorCount > 0) {
      this.showPasswordStrength = true;
      return;
    }

    this.showPasswordStrength = false;

    if (this.oldPassword.length > 0 && this.newPassword.length > 0) {
      const user = this.storageService.appUser;
      const objChangePassword = new ChangePasswordModel();
      objChangePassword.emailAddress = user.emailAddress;
      objChangePassword.externalUserId = user.externalId;
      objChangePassword.newPassword = this.newPassword;
      objChangePassword.oldPassword = this.oldPassword;

      this.isAsyncCall = true;

      this.profileService.changePassword(user.id, objChangePassword)
      .subscribe(() => {
          this.showToastMsg(true, 'Password changed successfully');
          this.isAsyncCall = false;
          const usr = this.storageService.appUser;
          if (!this.storageService.appUser.agreedTermsAndConditions) {
            usr.initialPasswordReset = true;
            this.storageService.appUser = usr;
            this.router.navigate([`/contract`]);
          } else {
            usr.agreedTermsAndConditions = true;
            usr.initialPasswordReset = true;
            this.storageService.appUser = usr;
            this.uiService.changeSession(true);
            this.router.navigate([`/dashboard`]);
          }
        },
        error => {
          error = error.error;
          if (error.messages && error.messages.length > 0) {
            this.showToastMsg(false, error.messages[0]);
          } else {
            this.showToastMsg(false, 'Oops! something went wrong.');
          }
          this.isAsyncCall = false;
        }
      );
    }
  }

  showToastMsg(val, msg) {
    const model: ToastModel = { icon: val ? 'gt-icon-sucess' : 'gt-icon-error', message: msg };
    this.toastService.toggleToast(model);
  }

  passwordTextChange() {

    this.errorCount = 0;
    this.numberPassword = false;
    this.uppercasePassword = false;
    this.lowercasePassword = false;

    const numberM = /([0-9])/;
    const lowercasePassword = /([a-z])/;
    const uppercasePassword = /([A-Z])/;

    const password = this.newPassword;

    if (!password) {
      return;
    }

    if (password.match(lowercasePassword)) {
      this.lowercasePassword = true;
    } else {
      this.lowercasePassword = false;
      this.errorCount += 1;
    }
    if (password.match(uppercasePassword)) {
      this.uppercasePassword = true;
    } else {
      this.uppercasePassword = false;
      this.errorCount += 1;
    }
    if (password.match(numberM)) {
      this.numberPassword = true;
    } else {
      this.numberPassword = false;
      this.errorCount += 1;
    }
    if (password.length < 8) {
      this.errorCount += 1;
    }
  }

}
