// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const serviceHosts = {
  assetsFolderBasePath: '/dist/assets',
  assetsFolderBasePathDev: '/assets',
  assetsFolderDev: '../../../../../assets'
};

export const environment = {
  production: false,
  enableDebug: true,
  serviceHosts: { serviceHosts },
  assetsRoot: serviceHosts.assetsFolderBasePath,
  assetsRootDev: serviceHosts.assetsFolderBasePathDev,
  imagesFolder: serviceHosts.assetsFolderBasePath + '/images',
  imagesFolderDev: serviceHosts.assetsFolderBasePathDev + '/images',
  DefaultImagePath: `/images/profile-placeholder-image.png`,
  DefaultImage: `/images/upload-img.png`,
  localUrl: 'https://localhost:44319/api/v1',
  liveUrl: 'https://eloomi-authoring-service.azurewebsites.net/api/v1',
  pexelsUrl: 'https://api.pexels.com/v1/',
  pexelsKey: '563492ad6f91700001000001e733bc8c8c6e4e94bfe5712590589a0b',
  intercomAppId: 'r3844d36'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
