import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-redirect-login',
  templateUrl: './auth-redirect-login.component.html',
  styleUrls: ['./auth-redirect-login.component.less']
})
export class AuthRedirectLoginComponent {

  constructor() { }
}
