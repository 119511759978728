import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../authentication/auth.service';
import { StorageService } from '../services/storage.service';
import { UIService } from '../services/ui.service';

@Injectable()
export class MemberGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private storageService: StorageService,
              private uiService: UIService) {
  }

  canActivate() {
    if (this.storageService.appUser) {
      if (!this.storageService.appUser.initialPasswordReset) {
        this.router.navigate([`/change-password`]);
      } else if (!this.storageService.appUser.agreedTermsAndConditions) {
        this.router.navigate([`/contract`]);
      } else if (this.isLogin()) {
        return true;
      }
    } else {
      this.router.navigate(['/auth/login']);
    }
    return false;
  }

  private isLogin() {
    if (this.authService.isAuthenticated() && this.storageService.appUser != null) {
      return true;
    } else {
      this.uiService.changeSession(false);
      return false;
    }
  }
}
