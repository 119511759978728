<nav class="sideNav">
    <ul>
    <li [class.active]="selectedIndex === tabType.Home"><a href="javascript:;" ><span class="gt-icon gt-icon-home"></span>Home</a></li>
        <li [class.active]="selectedIndex === tabType.DashBoard" *ngIf="isAdmin"><a routerLink="/dashboard" routerLinkActive="active"><span class="gt-icon gt-icon-dashboard"></span>Dashboard</a></li>
        <li  [class.active]="selectedIndex === tabType.Courses"><a routerLink="/courses" routerLinkActive="active"><span class="gt-icon gt-icon-playlists"></span>Templates</a></li>
        <li  [class.active]="selectedIndex === tabType.MyCourses"><a routerLink="/courses/mylist" routerLinkActive="active"><span class="gt-icon gt-icon-my-playlists"></span>{{isAdmin ? 'My Courses' : 'All Courses'}}</a></li>
        <li  [class.active]="selectedIndex === tabType.Stats" *ngIf="storageService.appUser && !storageService.appUser.isAdmin" ><a routerLink="/client-stats" routerLinkActive="active"><span class="gt-icon gt-icon-stats"></span>Stats</a></li>
        <li  [class.active]="selectedIndex === tabType.Stats" *ngIf="storageService.appUser && storageService.appUser.isAdmin"><a routerLink="/stats" routerLinkActive="active"><span class="gt-icon gt-icon-stats"></span>Stats</a></li>
        <li  [class.active]="selectedIndex === tabType.MyFiles"><a routerLink="/media" routerLinkActive="active"><span class="gt-icon gt-icon-my-files"></span>My Files</a></li>
        <li  [class.active]="selectedIndex === tabType.Support" *ngIf="false"><a routerLink="/help" routerLinkActive="active"><span class="gt-icon gt-icon-help"></span>Support</a></li>
    </ul>
</nav>
